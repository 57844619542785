import * as apiService from '../services/api.service';

export const SET_USER_LESSONS = 'userLessons/SET_LESSONS';
export const SET_USER_Tutee_Lesson = 'userLessons/SET_Tutee_Lesson';
export const SET_USER_LESSONS_LOADER = 'userLessons/SET_LOADER';

const initialState = {
  lessons: [],
  isLoading: false,
  user_tutee_lesson:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LESSONS:
      return {...state, lessons: [...action.lessons]};

    case SET_USER_LESSONS_LOADER:
      return {...state, isLoading: action.isLoading};

    case SET_USER_Tutee_Lesson:
      return {...state, user_tutee_lesson: action.user_tutee_lesson};

    default:
      return state;
  }
};


export const getUserLessons = () => dispatch => {
  dispatch({ type: SET_USER_LESSONS, lessons: [] });
  dispatch({ type: SET_USER_LESSONS_LOADER, isLoading: true });

  return apiService.getUserLessons()
    .then(({lessons = []}) => {
      dispatch({ type: SET_USER_LESSONS_LOADER, isLoading: false });
      return dispatch({ type: SET_USER_LESSONS, lessons });
    })
    .catch(error => {
      dispatch({ type: SET_USER_LESSONS_LOADER, isLoading: false });
      // if (typeof alert === 'function') alert(error);
    });
}


export const getTutorTuteeLesson = (user_uuid,tutee_uuid) => dispatch => {
  dispatch({ type: SET_USER_Tutee_Lesson, user_tutee_lesson: [] });

  return apiService.getTutorTuteeLesson(user_uuid,tutee_uuid)
    .then(({user_tutee_lesson = []}) => {
      return dispatch({ type: SET_USER_Tutee_Lesson, user_tutee_lesson });
    })
    .catch(error => {
      // if (typeof alert === 'function') alert(error);
    });
}
