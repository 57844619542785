import React from 'react';
import AdminTuteePage from '../AdminTuteePage';
import { ADMIN_ROUTES } from '../../routes';

class AdminTuteeAccountPage extends React.Component {
  goToClickHandler = (route, ev) => {
    this.props.goTo(route);
    ev.preventDefault();
  };

  render() {
    const {
      title = 'Tutee',
      location = {},
      children,
      style = {},
      overflowY = true,
      isSidebarVisible = true,
      goTo,
      session,
      tutee,
      page=''
    } = this.props;
    const { pathname } = location;
    //  console.log('tutee on admin acount page ', tutee);
    return (
      <AdminTuteePage
        id="account"
        title={title}
        style={style}
        location={location}
      >
        <div className="background-white admin-tab-content">
          {
            page=="factures" && (
              <div id="admin-sidebar" className="col-sm-3 col-xs-12 o-y-auto">
              <ul className="list-group">
                <li
                  className={`list-group-item ${
                    pathname === ADMIN_ROUTES.TUTEE_ACCOUNT_INVOICES
                      ? 'active profil-prof-active'
                      : ''
                  }`}
                  onClick={this.goToClickHandler.bind(
                    this,
                    ADMIN_ROUTES.TUTEE_ACCOUNT_INVOICES
                  )}
                >
                  Factures
                </li>
                <li
                    className={`list-group-item ${
                      pathname === ADMIN_ROUTES.TUTEE_ACCOUNT_AF
                        ? 'active profil-prof-active'
                        : ''
                    }`}
                    onClick={this.goToClickHandler.bind(
                      this,
                      ADMIN_ROUTES.TUTEE_ACCOUNT_AF
                    )}
                  >
                    Attestations fiscales
                  </li>
              </ul>
            </div>
            )
          }
          {isSidebarVisible && (
            <div id="admin-sidebar" className="col-sm-3 col-xs-12 o-y-auto">
              <ul className="list-group">
                <li
                  className={`list-group-item ${
                    pathname === ADMIN_ROUTES.TUTEE_ACCOUNT_PERSONAL_INFO
                      ? 'active profil-prof-active'
                      : ''
                  }`}
                  onClick={this.goToClickHandler.bind(
                    this,
                    ADMIN_ROUTES.TUTEE_ACCOUNT_PERSONAL_INFO
                  )}
                >
                  Informations personnelles
                </li>
                {!!tutee &&
                  (!tutee.have_besoins ||
                    (tutee.have_besoins && !tutee.have_besoin_pres && !tutee.have_besoin_visio && (tutee.wants_visio=='f' || tutee.wants_visio==false)) ||
                    (tutee.have_besoins && tutee.have_besoin_pres)) && (
                    <li
                      className={`list-group-item ${
                        pathname === ADMIN_ROUTES.TUTEE_SAI
                          ? 'active profil-prof-active'
                          : ''
                      }`}
                      onClick={this.goToClickHandler.bind(
                        this,
                        ADMIN_ROUTES.TUTEE_SAI
                      )}
                    >
                      Avance Immédiate
                    </li>
                  )}

                {/* <li
                  className={`list-group-item ${pathname === ADMIN_ROUTES.TUTEE_ACCOUNT_CHILDREN ? 'active profil-prof-active' : ''}`}
                  onClick={this.goToClickHandler.bind(this, ADMIN_ROUTES.TUTEE_ACCOUNT_CHILDREN)}
                >
                  Enfant(s)
                </li> */}

                {(session || {}).customer_type === 'client' && (
                  <li
                    className={`list-group-item ${
                      pathname === ADMIN_ROUTES.TUTEE_ACCOUNT_BANK_DETAILS
                        ? 'active profil-prof-active'
                        : ''
                    }`}
                    onClick={this.goToClickHandler.bind(
                      this,
                      ADMIN_ROUTES.TUTEE_ACCOUNT_BANK_DETAILS
                    )}
                  >
                    Coordonnées bancaires
                  </li>
                )}

                {/* <li
                  className={`list-group-item ${
                    pathname === ADMIN_ROUTES.TUTEE_ACCOUNT_INVOICES
                      ? 'active profil-prof-active'
                      : ''
                  }`}
                  onClick={this.goToClickHandler.bind(
                    this,
                    ADMIN_ROUTES.TUTEE_ACCOUNT_INVOICES
                  )}
                >
                  Factures
                </li> */}
              </ul>
            </div>
          )}

          <div
            className={`col-xs-12 ${isSidebarVisible ? 'col-sm-9' : ''} ${
              overflowY ? 'o-sm-y-auto' : ''
            }`}
          >
            {children}
          </div>
        </div>
      </AdminTuteePage>
    );
  }
}

export default AdminTuteeAccountPage;
