import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminPage from '../AdminPage';
import TuteeTopbar from '../TuteeTopbar';

class AdminTuteePage extends React.Component {
  render() {
    const {
      id = 'tutee-page',
      title = 'Tutee',
      currentUser,
      location: {pathname} = {},
      children,
      style = {},
      goTo
    } = this.props;

    return (
      <AdminPage id={id} title={title} style={style}>
        <TuteeTopbar
          currentUser={currentUser}
          pathname={pathname}
          goTo={goTo}
        />

        {children}
      </AdminPage>
    );
  }
};

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({}, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTuteePage);
