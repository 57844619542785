import { getUsersRequest, getUserTypesRequest } from '../services/api.service';

export const SEARCH_ITEMS_PER_PAGE = 10;

export const SET_CURRENT_USERS = 'search/SET_CURRENT_USERS';
export const SET_SEARCH_LOADER = 'search/SET_LOADER';
export const SET_TYPES = 'search/SET_TYPES';
export const SET_TEACH = 'search/SET_TEACH';
export const SET_LEVEL = 'search/SET_LEVEL';
export const SET_LOCATION = 'search/SET_LOCATION';
export const SET_SCHOOL_CATEGORIES = 'search/SET_SCHOOL_CATEGORIES';
export const RESET_FILTERS = 'search/RESET_FILTERS';

export const availableSchoolCategories = [
  { id: 'BUS', title: 'Écoles de commerce' },
  { id: 'ING', title: 'Écoles d’ingénieurs' },
  { id: 'ENS', title: 'Écoles Normales' },
  { id: 'SCP', title: 'Sciences politiques' },
  { id: 'OTH', title: 'Autres (Agreg, Masters, ENA...)' }
];

const initialState = {
  users: [],
  isLoading: false,

  // Filters
  teach: '',
  level: '',
  location: '',
  schoolCategories: availableSchoolCategories.map(c => c.id),

  // User types data
  levels: [],
  topics: [],
  categories: [],
  isUserTypesLoaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USERS:
      return _setUsers(state, action.usersResponse || {});

    case SET_SEARCH_LOADER:
      return { ...state, isLoading: action.isLoading || false };

    case SET_TYPES:
      return _setUserTypes(state, action.types);

    case SET_TEACH:
      return { ...state, teach: action.teach || '' };

    case SET_LEVEL:
      return { ...state, level: action.level || '' };

    case SET_LOCATION:
      return { ...state, location: action.location || '' };

    case SET_SCHOOL_CATEGORIES:
      return { ...state, schoolCategories: action.schoolCategories || [] };

    case RESET_FILTERS:
      return {
        ...state,
        teach: '',
        level: '',
        location: '',
        schoolCategories: availableSchoolCategories.map(c => c.id)
      };

    default:
      return state;
  }
};

const _setUsers = (state, { users = [] }) => ({
  ...state,
  users
});

const _setUserTypes = (
  state,
  { levels = {}, topics = {}, categories = [] }
) => {
  levels = Object.keys(levels).map(key => levels[key]);
  topics = Object.keys(topics).map(key => topics[key]);

  // Better if topic.cat will be lowercase, the same like category.slug
  topics.forEach(topic => (topic.cat = topic.cat.toLowerCase()));

  // Add topics in each category
  categories.forEach(
    cat =>
      (cat.topics = topics.filter(
        topic => topic.cat.toLowerCase() === cat.slug
      ))
  );

  return { ...state, levels, userTopics: topics, categories, isUserTypesLoaded: true };
};

// Actions

export const setTeach = teach => dispatch => {
  dispatch({ type: SET_TEACH, teach });
  return Promise.resolve();
};

export const setLevel = level => dispatch => {
  dispatch({ type: SET_LEVEL, level });
  return Promise.resolve();
};

export const setLocation = location => dispatch => {
  dispatch({ type: SET_LOCATION, location });
  return Promise.resolve();
};

export const setSchoolCategories = schoolCategories => dispatch => {
  dispatch({ type: SET_SCHOOL_CATEGORIES, schoolCategories });
  return Promise.resolve();
};

export const resetSearchFilters = () => dispatch => {
  dispatch({ type: RESET_FILTERS });
  return Promise.resolve();
};

export const getCurrentUsers = (filters = {}, options = {}) => dispatch => {
  filters.teach = filters.teach || initialState.teach;
  filters.level = filters.level || initialState.level;
  filters.location = filters.location || initialState.location;
  filters.schoolCategories = [
    ...(filters.schoolCategories || initialState.schoolCategories || [])
  ];

  // If all categories selected we need to remove schoolCategories from request
  {
    filters.schoolCategories.sort();
    const availableSchoolCategoriesIdsStr = availableSchoolCategories
      .map(c => c.id)
      .sort()
      .toString();
    if (
      availableSchoolCategoriesIdsStr === filters.schoolCategories.toString()
    ) {
      filters.schoolCategories = [];
    }
  }

  options.from = typeof options.from !== 'undefined' ? options.from : 1;
  dispatch({ type: SET_SEARCH_LOADER, isLoading: true });

  return getUsersRequest(filters, options)
    .then((usersResponse = {}) => {
      dispatch({ type: SET_CURRENT_USERS, usersResponse });
      return usersResponse;
    })
    .catch(error => {
      // if (typeof alert === 'function') alert(error);
      return error;
    })
    .finally(() => dispatch({ type: SET_SEARCH_LOADER, isLoading: false }));
};

export const getUserTypes = () => dispatch =>
  new Promise(resolve => {
    getUserTypesRequest()
      .then(types => {
        dispatch({ type: SET_TYPES, types });
        resolve();
      })
      .catch(error => {
        console.log(error);
        // if (typeof alert === 'function') alert(error);
        resolve();
      });
  });

//export const getUserTypes = () => dispatch => {
//  let types = JSON.parse(localStorage.getItem('clevermate-types'));
//
//  if (types) {
//    dispatch({ type: SET_TYPES, types });
//    return;
//  }
//
//  getUserTypesRequest()
//    .then(types => {
//      localStorage.setItem('clevermate-types', JSON.stringify(types));
//      dispatch({ type: SET_TYPES, types });
//    })
//    .catch(error => {
//      if (typeof alert === 'function') alert(error);
//    });
//};

export const removeCurrentUsers = () => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_CURRENT_USERS, users: [] });

    resolve({});
  });
