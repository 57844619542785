import { getStaticPage as getStaticPageRequest } from '../services/api.service';

export const SET_STATIC_PAGE_LOADER = 'staticPage/SET_STATIC_PAGE_LOADER';
export const SET_STATIC_HTML = 'staticPage/SET_STATIC_HTML';

const initialState = { staticHTML: '', isStaticPageLoading: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STATIC_HTML:
      return {
        ...state,
        staticHTML: action.staticHTML
      };
    case SET_STATIC_PAGE_LOADER:
      return {
        ...state,
        isStaticPageLoading: action.value
      };

    default:
      return state;
  }
};

export const getStaticPage = pageSlug => dispatch =>
  new Promise(resolve => {
    dispatch(setStaticPageLoader(true));

    getStaticPageRequest(pageSlug)
      .then(staticHTML => {
        dispatch(setStaticHTML(staticHTML));
        dispatch(setStaticPageLoader(false));
        resolve();
      })
      .catch(() => {
        dispatch(setStaticPageLoader(false));
        resolve();
      });
  });

export const setStaticPageLoader = value => ({
  type: SET_STATIC_PAGE_LOADER,
  value
});
export const setStaticHTML = staticHTML => ({
  type: SET_STATIC_HTML,
  staticHTML
});
