import React from 'react';
import LazyLoad from 'react-lazyload'
import {
  StyledFooter, Container, Col, ColTitle, ColItem, FooterLogo, StyledStaticRouterLink, StyledLink,
  StyledIconLink
} from './footer.styled';
import { SOCIAL_LINKS } from './footer.constants';
import { ROUTES } from '../../routes';
import { useLocation } from 'react-router-dom';

function Footer() {
  const { pathname } = useLocation();
  return (
    <StyledFooter>
      <div className="container">
        <Container>
          {pathname === '/donner-cours-particuliers' && <Col style={{ textAlign: 'center' }}>
            <img
              src="/img/google.review.png"
              alt="Logo Footer"
              width={128}
              height={56}
            />
          </Col>}
          <Col>
            <ColTitle>A propos</ColTitle>

            {/* <ColItem>
              <StyledStaticRouterLink>Nos valeurs</StyledStaticRouterLink>
            </ColItem> */}
            <ColItem>
              <StyledStaticRouterLink route="/contact">Equipe</StyledStaticRouterLink>
            </ColItem>

            <ColItem>
              <StyledStaticRouterLink route="/conditions-generales">Mentions légales</StyledStaticRouterLink>
            </ColItem>
          </Col>

          <Col>
            <ColTitle>En savoir plus</ColTitle>

            <ColItem>
              <StyledStaticRouterLink route="/contact">Aide</StyledStaticRouterLink>
            </ColItem>

            <ColItem>
              <StyledStaticRouterLink route="/contact">Contact</StyledStaticRouterLink>
            </ColItem>

            <ColItem>
              <StyledStaticRouterLink route="/tarifs">Tarifs</StyledStaticRouterLink>
            </ColItem>

            {/* <ColItem>
              <StyledStaticRouterLink route="/tarifs">Comment ça marche ?</StyledStaticRouterLink>
            </ColItem> */}
          </Col>

          <Col>
            <ColTitle>Découvrir</ColTitle>

            <ColItem>
              <a style={{
                color: 'white',
                whiteSpace: 'nowrap',
              }} href={ROUTES.REVIEWS}>Avis</a>
            </ColItem>

            <ColItem>
              <StyledLink href={process.env.NEXT_PUBLIC_MAGAZINE_URL}>Le Magazine</StyledLink>
            </ColItem>

            <ColItem>
              <StyledStaticRouterLink route="/recrutement">Jobs</StyledStaticRouterLink>
            </ColItem>
          </Col>

          <Col className="social-links">
            {SOCIAL_LINKS.map(((item, index) => (
              <ColItem key={index}>
                <StyledIconLink {...item} />
              </ColItem>
            )))}
          </Col>
        </Container>
      </div>
    </StyledFooter>
  )
}

export default Footer;