import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import AuthenticatedRoute from '../components/authenticated-route';
import UnAuthenticatedRoute from '../components/unauthenticated-route';
// import UnauthenticatedRoute from '../components/unauthenticated-route';
import { justifyName } from '../utils';
// import { CITIES, TOPICS } from './old-seo-data.mockup';
import { ADMIN_ROUTES, AdminRoutes } from '../admin/routes';

import NotFound from './not-found';

export const ROUTES = {
  HOME: '/',
  SEARCH: '/search/:teach?/:level?/:location?',
  PROFILE: '/profile/:id-:name-:school',
  DASHBOARD: '/dashboard',
  SCHOOLS: '/not-found',
  SCHOOL: '/ecole/:id',
  REVIEWS: '/avis',
  SIGN_UP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password/:token',
  LOGOUT: '/logout',
  COURS_CITY: '/cours-particuliers/toutes-matieres/:city',
  COURS_TOPIC: '/cours-particuliers/:topic',
  COURS_TOPIC_CITY: '/cours-particuliers/:topic/:city',
  COURS_PRIVATE: '/donner-cours-particuliers',
  COURS_PRIVATE_CITY: '/donner-cours-particuliers/:city',
  COURS_LEVEL: '/cours-particuliers/par-niveau/:level',
  ...ADMIN_ROUTES
};

const prepareTopicName = topic => {
  const topicName = justifyName(topic);

  return topicName === 'mathematiques' ? 'maths' : topicName;
}

export const linkService = {
  getProfileLink: (id, name, school) =>
    ROUTES.PROFILE.replace(':id', id)
      .replace(':name', name)
      .replace(':school', school),

  getSearchLink: (teach, level, location) => ROUTES.SEARCH.replace(':teach?', teach || 'all')
    .replace(':level?', level || 'all')
    .replace(':location?', location || 'all'),

  getSchoolPath: slug => ROUTES.SCHOOL.replace(':id', slug),

  getCoursCityLink: city => ROUTES.COURS_CITY.replace(':city', justifyName(city)),

  getCoursTopicLink: topic => ROUTES.COURS_TOPIC.replace(':topic', prepareTopicName(topic)),

  getCoursLevelLink: level => ROUTES.COURS_LEVEL.replace(':level', level),

  getCoursTopicCityLink: (topic, city) => ROUTES.COURS_TOPIC_CITY
    .replace(':topic', prepareTopicName(topic))
    .replace(':city', justifyName(city))
};



export const isCoursCityRoute = route =>
  (new RegExp("^\/cours\-particuliers\/toutes-matieres\/[a-z0-9-_+]+$", 'i')).test(route);

export const isCoursTopicRoute = route =>
  (new RegExp("^\/cours\-particuliers\/[a-z0-9-_+]+$", 'i')).test(route);

export const isCoursTopicCityRoute = route =>
  (new RegExp("^\/cours\-particuliers\/[^\/]+\/[a-z0-9-_+]+$", 'i')).test(route);

export const isCoursLevelRoute = route =>
  (new RegExp("^\/cours\-particuliers\/par-niveau\/[^\/]+\/[a-z0-9-_+]+$", 'i')).test(route);

export const isCoursPrivateRoute = route => route === ROUTES.COURS_PRIVATE;

export const isCoursPrivateCityRoute = route =>
  (new RegExp("^\/donner-cours-particuliers\/[a-z0-9-_+]+$", 'i')).test(route);


// const Homepage = Loadable({
//   loader: () => import(/* webpackChunkName: "homepage" */ './homepage'),
//   loading: () => null,
//   modules: ['./homepage', 'homepage.scss']
// });

// const Search = Loadable({
//   loader: () => import(/* webpackChunkName: "search" */ './search'),
//   loading: () => null
// });

const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "dashboard" */ './dashboard'),
  loading: () => null
});

const Logout = Loadable({
  loader: () => import(/* webpackChunkName: "logout" */ './logout'),
  loading: () => null
});

// const Profile = Loadable({
//   loader: () => import(/* webpackChunkName: "profile" */ './profile'),
//   loading: () => null
// });

const SEOPages = Loadable({
  loader: () => import(/* webpackChunkName: "seo-pages" */ './seoPages'),
  loading: () => null,
  modules: ['./seoPages', 'seoPages.scss']
});

const Schools = Loadable({
  loader: () => import(/* webpackChunkName: "schools" */ './schools'),
  loading: () => null
});

const School = Loadable({
  loader: () => import(/* webpackChunkName: "school" */ './school'),
  loading: () => null
});

const StaticPage = Loadable({
  loader: () => import(/* webpackChunkName: "static" */ './staticPages'),
  loading: () => null
});

// const CoursCity = Loadable({
//   loader: () => import(/* webpackChunkName: "cours-city" */ './cours/City'),
//   loading: () => null,
//   modules: ['./cours/City', 'coursCity.scss', 'seoPages.scss', 'homepage.scss']
// });

// const CoursTopic = Loadable({
//   loader: () => import(/* webpackChunkName: "cours-topic" */ './cours/Topic'),
//   loading: () => null
// });

// const CoursTopicCity = Loadable({
//   loader: () => import(/* webpackChunkName: "cours-topic-city" */ './cours/TopicCity'),
//   loading: () => null
// });

// const CoursLevel = Loadable({
//   loader: () => import(/* webpackChunkName: "cours-level" */ './cours/Level'),
//   loading: () => null
// });

const CoursPrivate = Loadable({
  loader: () => import(/* webpackChunkName: "cours-private" */ './cours/private/Main'),
  loading: () => null
});

const CoursPrivateCity = Loadable({
  loader: () => import(/* webpackChunkName: "cours-private-city" */ './cours/private/City'),
  loading: () => null
});

const Reviews = Loadable({
  loader: () => import(/* webpackChunkName: "reviews" */ './reviews'),
  loading: () => null
});

const SignUp = Loadable({
  loader: () => import(/* webpackChunkName: "sign-up" */ './sign-up'),
  loading: () => null
});

const ForgotPassword = Loadable({
  loader: () => import(/* webpackChunkName: "static" */ './forgot-password'),
  loading: () => null
});

const staticPages = process.env.NEXT_PUBLIC_STATIC_PAGES;
// const seoCitiesPages = CITIES.join('|');
// const seoTopicsPages = TOPICS.join('|');

const RedirectWithStatus = ({ status, from, to }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) staticContext.status = status;
      return <Redirect from={from} to={to} />;
    }}
  />
);


export default (extraProps = {}) => (
  <Switch>
    {/* <Route exact path={ROUTES.HOME} component={Homepage} /> */}
    {/* <Route exact path={ROUTES.SEARCH} component={Search} /> */}

    {/* <Route exact path={ROUTES.PROFILE} component={Profile} /> */}
    <Route exact path={ROUTES.SCHOOLS} component={Schools} />
    <Route exact path={ROUTES.SCHOOL} component={School} />
    <Route exact path={ROUTES.REVIEWS} render={props => <Reviews {...extraProps} {...props} />} />
    {/* <Route exact path={ROUTES.COURS_CITY} component={CoursCity} />
    <Route exact path={ROUTES.COURS_LEVEL} component={CoursLevel} /> */}

    {/* MATHS Redirects */}
    {/* <Route
      exact
      path={`/cours-particuliers/:topic(mathematiques|mathématiques)`}
      render={() => <RedirectWithStatus status={301} to={`${linkService.getCoursTopicLink('maths')}`} />}
    /> */}
    {/* <Route
      exact
      path={`/cours-particuliers/:topic(mathematiques|mathématiques)/:city`}
      render={props => (
        <RedirectWithStatus status={301} to={`${linkService.getCoursTopicLink('maths')}/${props.match.params.city}`} />
      )}
    /> */}
{/* 
    <Route exact path={ROUTES.COURS_TOPIC} component={CoursTopic} />
    <Route exact path={ROUTES.COURS_TOPIC_CITY} component={CoursTopicCity} /> */}
    <Route exact path={ROUTES.COURS_PRIVATE} render={props => <CoursPrivate {...extraProps} {...props} />} />
    <Route exact path={ROUTES.COURS_PRIVATE_CITY} render={props => <CoursPrivateCity {...extraProps} {...props} />} />

    <AuthenticatedRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />

    {/* ADMIN */}
    {AdminRoutes.map((routeProps, index) => (
      <AuthenticatedRoute exact
          key={`admin-route-component-${index}`}
          {...routeProps}
          extraProps={extraProps}
      />
    ))}

    <UnAuthenticatedRoute exact path={ROUTES.SIGN_UP} extraProps={extraProps} component={SignUp} />
    <Route exact path={ROUTES.FORGOT_PASSWORD} render={props => <ForgotPassword {...extraProps} {...props} />} />
    <AuthenticatedRoute exact path={ROUTES.LOGOUT} component={Logout} />

    {/*
      REDIRECTS ->
    */}

    {/* {seoTopicsPages && (
      <Route
        exact
        path="/proposer-un-cours"
        render={props => <RedirectWithStatus status={301} to={ROUTES.COURS_PRIVATE} />}
      />
    )} */}

    {/* {seoTopicsPages && (
      <Route
        exact
        path={`/:topic(${seoTopicsPages})/page/:page`}
        render={props => <RedirectWithStatus status={301} to={`${linkService.getCoursTopicLink(props.match.params.topic)}`} />}
      />
    )}

    {seoCitiesPages && (
      <Route
        exact
        path={`/:city(${seoCitiesPages})/page/:page`}
        render={props => <RedirectWithStatus status={301} to={`${linkService.getCoursCityLink(props.match.params.city)}`} />}
      />
    )} */}

    {/* {seoTopicsPages && (
      <Route
        exact
        path={`/:topic(${seoTopicsPages})`}
        render={props => <RedirectWithStatus status={301} to={linkService.getCoursTopicLink(props.match.params.topic)} />}
      />
    )}

    {seoCitiesPages && (
      <Route
        exact
        path={`/:city(${seoCitiesPages})`}
        render={props => <RedirectWithStatus status={301} to={linkService.getCoursCityLink(props.match.params.city)} />}
      />
    )} */}

    {/* {seoTopicsPages && seoCitiesPages && (
      <Route
        exact
        path={`/:topic(${seoTopicsPages})/:city(${seoCitiesPages})`}
        render={({ match: { params: { topic, city } } }) => (
          <RedirectWithStatus status={301} to={`${linkService.getCoursTopicLink(topic)}/${city}`} />
        )}
      />
    )}

    {seoTopicsPages && seoCitiesPages && (
      <Route
        exact
        path={`/:topic(${seoTopicsPages})/:city(${seoCitiesPages})/page/:page`}
        render={({ match: { params: { topic, city } } }) => (
          <RedirectWithStatus status={301} to={`${linkService.getCoursTopicLink(topic)}/${city}`} />
        )}
      />
    )} */}


    {/* {seoTopicsPages && seoCitiesPages && (
      <Route
        exact
        path={`/:city(${seoCitiesPages})/:topic(${seoTopicsPages})`}
        render={({ match: { params: { topic, city } } }) => (
          <RedirectWithStatus status={301} to={`${linkService.getCoursTopicLink(topic)}/${city}`} />
        )}
      />
    )}

    {seoTopicsPages && seoCitiesPages && (
      <Route
        exact
        path={`/:city(${seoCitiesPages})/:topic(${seoTopicsPages})/page/:page`}
        render={({ match: { params: { topic, city } } }) => (
          <RedirectWithStatus status={301} to={`${linkService.getCoursTopicLink(topic)}/${city}`} />
        )}
      />
    )} */}

    {/*
      <- REDIRECTS
    */}

    {staticPages && (
      <Route
        exact
        path={`/:staticPage(${staticPages})`}
        component={StaticPage}
      />
    )}

    {/* <Route component={SEOPages} /> */}

    <Route
      render={({ staticContext, ...others }) => {
        // console.log({staticContext, others, env:process.env.NEXT_PUBLIC_HTTP_STATUS_NOT_FOUND})
        if (staticContext) {
          // console.log("return not found", process.env.NEXT_PUBLIC_HTTP_STATUS_NOT_FOUND)
          staticContext.status = process.env.NEXT_PUBLIC_HTTP_STATUS_NOT_FOUND;
        }
        return <NotFound />;
    }} />

    {/*
      Currently SEOPages do work of NotFound route, and handle all not handled routes before.
      Need to uncomment next line if this behavior will change:
        <Route component={NotFound} />

      NotFound component is used inside compoenents: SEOPages, CoursCity, CoursTopic
    */}
  </Switch>
);
