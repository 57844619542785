import axios from 'axios';
import { isNode } from "browser-or-node";
export const SET_SEO_PAGE_LOADER = 'seoPages/SET_SEO_PAGE_LOADER';
export const GET_SEO_PAGE = 'seoPages/GET_SEO_PAGE';
export const SET_SEO_PROFILES = 'seoPages/SET_SEO_PROFILES';
export const SET_SEO_PAGE_EXIST = 'seoPages/SET_SEO_PAGE_EXIST';

import { isServer } from '../store';
let isFirstLoad = true;

const CURRENT_BASE_URL = isNode
  ? process.env.NEXT_PUBLIC_LOCAL_BASE_URL
  : process.env.NEXT_PUBLIC_BASE_URL;
const CURRENT_BASE_URL_V3 = isNode
  ? process.env.NEXT_PUBLIC_LOCAL_BASE_URL_V3
  : process.env.NEXT_PUBLIC_BASE_URL_V3;

const initialState = {
  seoPageExist: false,
  teachers: [],
  subjects: [],
  cities: [],
  text: {},
  city: '',
  subject: '',
  isCity: false,
  isSubject: false,
  citySlug: '',
  subjectSlug: '',
  isSeoPageLoading: false,
  totalItems: 0,
  limit: 81
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SEO_PAGE:
      return { ...state, ...action.payload };

    case SET_SEO_PROFILES:
      return { ...state, ...action.payload };

    case SET_SEO_PAGE_LOADER:
      return { ...state, isSeoPageLoading: action.value };

    case SET_SEO_PAGE_EXIST:
      return { ...state, seoPageExist: action.seoPageExist };

    default:
      return state;
  }
};

export const getSeoPageInfo = props => dispatch =>
  new Promise(resolve => {
    // to not show spinner on first load
    if (!isServer && isFirstLoad) {
      isFirstLoad = false;
      dispatch(setSeoPageLoader(true));
    }

    dispatch({type: SET_SEO_PAGE_EXIST, seoPageExist: false});

    const path = ((props.location && props.location.pathname) || '').slice(1);
    const splittedPath = path.split('/');
    const limit = props.limit || 81;
    let isCity = false;
    let isSubject = false;
    let citySlug = 'all';
    let subjectSlug = 'all';
    let isPage = path.indexOf('page/') > -1;
    let indexOfPage = isPage && (path.indexOf('page/') + 5);
    let page = isPage && parseInt(path.slice(indexOfPage));
    let offset = isPage ? limit * page - limit : 0;


    axios
      .get(`${CURRENT_BASE_URL_V3}/seo/subjects-cities`)
      .then(res => res.data && res.data.data)
      .then(({ cities, subjects }) => {
        if (
          splittedPath[1] &&
          splittedPath[0] &&
          cities.find(c => c.slug === splittedPath[1]) &&
          subjects.find(s => s.slug === splittedPath[0])
        ) {
          citySlug = splittedPath[1];
          subjectSlug = splittedPath[0];
          isCity = true;
          isSubject = true;
        } else if (
          (splittedPath[0] && cities.find(c => c.slug === splittedPath[0])) ||
          subjects.find(s => s.slug === splittedPath[0])
        ) {
          isCity = cities.some(c => c.slug === splittedPath[0]);
          isSubject = subjects.some(s => s.slug === splittedPath[0]);
          citySlug = isCity ? splittedPath[0] : 'all';
          subjectSlug = isSubject ? splittedPath[0] : 'all';
        } else {
          // props.history.push('/');
          dispatch(setSeoPageLoader(false));
          resolve();
          return;
        }

        axios
          .get(`${CURRENT_BASE_URL_V3}/seo/subject/${subjectSlug}/city/${citySlug}?limit=${limit}&offset=${offset}`)
          .then(res => res && res.data && res.data.data)
          .then(data => {
            const {
              teachers,
              text,
              pagination: { total_items }
            } = data;

            dispatch({
              type: GET_SEO_PAGE,
              payload: {
                teachers,
                subjects,
                cities,
                text,
                citySlug,
                subjectSlug,
                city: isCity ? cities.find(c => c.slug === citySlug).name : '',
                subject: isSubject
                  ? subjects.find(s => s.slug === subjectSlug).name
                  : '',
                isCity,
                isSubject,
                totalItems: total_items,
                page,
                offset
              }
            });
            dispatch({type: SET_SEO_PAGE_EXIST, seoPageExist: true});
            dispatch(setSeoPageLoader(false));
            resolve();
          })
          .catch(err => {
            // props.history.push('/');
            dispatch(setSeoPageLoader(false));
            resolve();
          });
      });
  });

//export const getSeoPageProfiles = props => dispatch =>
//  new Promise(resolve => {
//    dispatch(setSeoPageLoader(true));
//
//    const { citySlug, subjectSlug, offset, limit } = props;
//
//    axios
//      .get(`${CURRENT_BASE_URL_V3}/seo/subject/${subjectSlug}/city/${citySlug}?limit=${limit}&offset=${offset}`)
//      .then(res => res && res.data && res.data.data)
//      .then(data => {
//        const {
//          teachers,
//          text,
//          pagination: { total_items }
//        } = data;
//
//        dispatch({
//          type: SET_SEO_PROFILES,
//          payload: { teachers, text, totalItems: total_items }
//        });
//        dispatch(setSeoPageLoader(false));
//        resolve();
//      })
//      .catch(err => {
//        props.history.push('/');
//        dispatch(setSeoPageLoader(false));
//        resolve();
//      });
//  });

export const setSeoPageLoader = value => ({
  type: SET_SEO_PAGE_LOADER,
  value
});

export const refreshSeoPageData = () => dispatch =>
  dispatch({
    type: GET_SEO_PAGE,
    payload: initialState
  });
