import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import './box.scss';
import { COLORS } from "../colors/colors.constants";

export const HEADER_LINE_WIDTH = 412;

const Base = ({
  component = 'div', children,
  flex, flexDirectionColumn, justifyContentCenter, alignItemsCenter, f, flexWrap, flexBasis, flexShrink, flexGrow,
  white, red, blue, backgroundColor, backgroundWhite, backgroundGrey,
  fontSize, fontWeight, lineHeight, textAlign, height, maxWidth, margin,
  
  // unnecessary props we can add here, to not add them to dom element attrs
  itemXsMb, smMinHeight, left, withDevider, upperCase,
  lineBlue, lineRed, lineWhite, lineLeft, lineCenter, lineBottom,
  
  ...props
}) => React.createElement(component, props, children);

const generateStyleParam = (value, name, withPx = false) =>
  value ? `${name}: ${value}${withPx && !isNaN(value) ? 'px' : ''};` : '';

const BoxStyled = styled(Base)`
  ${props => {
  const handler = val => generateStyleParam(val, 'background-color');
  
  if (props.backgroundWhite) { return handler(COLORS.WHITE); }
  if (props.backgroundGrey) { return handler(COLORS.GREY); }
  
  return handler(props.backgroundColor);
}}

  ${props => {
  const handler = val => generateStyleParam(val, 'color');
  
  if (props.white) { return handler(COLORS.WHITE); }
  if (props.red) { return handler(COLORS.RED); }
  if (props.blue) { return handler(COLORS.BLUE); }
  
  return '';
}}

  ${props => generateStyleParam(props.flex ? 'flex' : null, 'display')}
  ${props => generateStyleParam(props.flexDirectionColumn ? 'column' : null,
  'flex-direction')}
  ${props => generateStyleParam(props.justifyContentCenter ? 'center' : null,
  'justify-content')}
  ${props => generateStyleParam(props.alignItemsCenter ? 'center' : null,
  'align-items')}

  ${props => generateStyleParam(props.f, 'flex')}
  ${props => generateStyleParam(props.flexShrink, 'flex-shrink')}
  ${props => generateStyleParam(props.flexGrow, 'flex-grow')}
  ${props => generateStyleParam(props.flexWrap, 'flex-wrap')}
  ${props => generateStyleParam(props.flexBasis, 'flex-basis')}

  ${props => generateStyleParam(props.fontSize, 'font-size', true)}
  ${props => generateStyleParam(props.fontWeight, 'font-weight')}
  ${props => generateStyleParam(props.lineHeight, 'line-height')}
  ${props => generateStyleParam(props.textAlign, 'text-align')}
  ${props => generateStyleParam(props.height, 'height', true)}
  ${props => generateStyleParam(props.maxWidth, 'max-width', true)}
  ${props => generateStyleParam(props.margin, 'margin', true)}
`;

const Box = ({ className, ...rest }) => {
  const sizesClassNames = [];
  const props = {};
  
  Object.keys(rest).forEach(propName => {
    const value = rest[propName];
    const sizeProp = /^[mp][trblxy]?$/.test(propName);
    
    if (!sizeProp) {
      props[propName] = value;
    }
    else if (!isNaN(value)) {
      sizesClassNames.push(`${propName}-${value}`);
    }
  });
  
  return (
    <BoxStyled {...props} className={clsx(sizesClassNames, className)}/>
  );
};

Box.defaultProps = {
  component: 'div',
  justifyContentCenter: false,
  alignItemsCenter: false,
  f: null,
  flex: false,
  flexDirectionColumn: false,
  flexShrink: null,
  flexGrow: null,
  flexBasis: null,
  flexWrap: null,
  
  white: null,
  red: null,
  blue: null,
  backgroundColor: null,
  backgroundWhite: null,
  backgroundGrey: null,
  
  fontSize: null,
  fontWeight: null,
  lineHeight: null,
  textAlign: null,
  
  maxWidth: null,
  height: null,
  margin: null
};

export default Box;