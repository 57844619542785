import * as apiService from '../services/api.service';

export const SET_INFOCARD = 'infocard/SET_INFOCARD';
export const SET_INFOCARD_LOADER = 'infocard/SET_LOADER';
export const SET_INFOCARD_SAVING_LOADER = 'infocard/SET_SAVING_LOADER';

const initialState = {
  infocard: {},
  isLoading: false,
  isSaving: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INFOCARD:
      return {...state, infocard: {...action.infocard}};

    case SET_INFOCARD_LOADER:
      return {...state, isLoading: action.isLoading};

    case SET_INFOCARD_SAVING_LOADER:
      return {...state, isSaving: action.isSaving};

    default:
      return state;
  }
};


export const getInfocard = (userUuid) => dispatch => {
  dispatch({ type: SET_INFOCARD, infocard: {} });
  dispatch({ type: SET_INFOCARD_LOADER, isLoading: true });

  return apiService.getInfocard(userUuid)
    .then(({description: infocard = {}}) => {
      if ((infocard.locations || []).length === 0 && infocard.location) {
        infocard.locations = [infocard.location];
      }

      dispatch({ type: SET_INFOCARD_LOADER, isLoading: false });
      return dispatch({ type: SET_INFOCARD, infocard });
    })
    .catch(error => {
      dispatch({ type: SET_INFOCARD_LOADER, isLoading: false });
      // if (typeof alert === 'function') alert(error);
    });
}


export const saveInfocard = (userUuid, description) => dispatch => {
  dispatch({ type: SET_INFOCARD_SAVING_LOADER, isSaving: true });
console.log('saving ..', description );
let locations=[];
locations = description.locations
Array.from(locations).forEach((loc, index)=>{
  let {street} = loc
  let arr = street.split(',')
  if(arr.length && arr.length>2){
    arr.splice(arr.length -2, 2)
    description.locations[index].street = arr.join(',')
  }
})

console.log('new description', description)
  return apiService.setInfocard(userUuid, description)
    .then(() => {
      dispatch({ type: SET_INFOCARD_SAVING_LOADER, isSaving: false });

      return dispatch(getInfocard(userUuid));
    })
    .catch(error => {
      dispatch({ type: SET_INFOCARD_SAVING_LOADER, isSaving: false });
      // if (typeof alert === 'function') alert(error);
    });
}