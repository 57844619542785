import React from 'react';
import styled from 'styled-components';
import StaticRouterLink from '../static-router-link';

export const StyledFooter = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: '#262626',
  padding: '48px 0',
  color: '#ffffff',
  fontSize: 16,
});

export const FooterLogo = styled.div({
  display: 'flex',
  margin: '10px auto',

  '> a': {
    display: 'inline-block',
    width: 222,
    height: 41,
    background: 'url(/img/home/logo-white.png) no-repeat',
    backgroundSize: 'contain',
    margin: '0 auto',
  }
});

export const Container = styled.div({
  display: 'flex',
  justifyContent: 'space-around',

  '@media(max-width: 767px)': {
    flexDirection: 'column'
  }
});

export const Col = styled.div({
  // flexGrow: 1,
  // flexShrink: 0,
  padding: 10,
  '@media(max-width: 767px)': {
    '&.social-links': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around'
    }
  },
  // '@media(min-width: 768px)': {
  //   '&.social-links': {
  //     flexGrow: 0,
  //   }
  // }
});

export const ColTitle = styled.div({
  marginBottom: 20,
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  '@media(max-width: 767px)': {
    marginBottom: 0
  }
});

export const ColItem = styled.div({
  padding: '2px 0'
});

const styledLinkStyles = {
  color: '#ffffff !important',
  whiteSpace: 'nowrap',

  '&:hover': {
    textDecoration: 'underline !important'
  }
};

export const StyledLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener"
})(styledLinkStyles);

export const StyledStaticRouterLink = styled(StaticRouterLink)(styledLinkStyles);

export const StyledIconLink = styled(
  ({ icon, size, ...rest }) => <a {...rest} target="_blank" rel="noopener" />
)(({ icon, size = 25 }) => ({
  display: 'block',
  width: size,
  height: size,
  // margin: 3,
  backgroundImage: `url(/${icon})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
}));

