import { SEARCH_ITEMS_PER_PAGE } from '../reducers';
import { linkService } from '../routes';

export const compareSearchFilters = (
  matchFilters = {},
  reducerStateFilters = {}
) => {
  const teach =
    matchFilters.teach && matchFilters.teach !== 'all'
      ? matchFilters.teach
      : reducerStateFilters.teach;
  const level =
    matchFilters.level && matchFilters.level !== 'all'
      ? matchFilters.level
      : reducerStateFilters.level;
  const location =
    matchFilters.location && matchFilters.location !== 'all'
      ? matchFilters.location
      : reducerStateFilters.location;

  return {
    teach,
    level,
    location
  };
};

export const getFilteredUsers = ({ pageNumber = 1, users = [] }) => {
  users = users || [];

  let filteredUsers = [...users];

  // Filter empty school name
  filteredUsers = filteredUsers.filter(
    u => u.profile.school && u.profile.school.uuid
  );

  const from = (pageNumber - 1) * SEARCH_ITEMS_PER_PAGE;
  const to = from + SEARCH_ITEMS_PER_PAGE;

  return {
    totalCount: filteredUsers.length,
    from,
    to,
    users: filteredUsers.slice(from, to)
  };
};

export const removeMarker = marker => {
  if (marker && marker.setMap) {
    marker.setMap(null);
  }
};

export const setGoogleMapMarkers = (users = [], map, markersToBeRemoved) => {
  if (typeof google === 'undefined') {
    return [];
  }

  // Clean markers
  if (Array.isArray(markersToBeRemoved)) {
    markersToBeRemoved.forEach(removeMarker);
  }

  let infoWindow = null;
  const markers = [];

  for (let i = 0; i < users.length; i++) {
    const user = users[i];
    const profile = user.profile;
    const href = linkService.getProfileLink(
      profile.id,
      profile.first_name,
      profile.school.slug
    );
    let marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        profile.location.geo.lat,
        profile.location.geo.lng
      ),
      map,
      icon: `${process.env.NEXT_PUBLIC_PUBLIC_URL}/img/map/custom_red.png`
    });

    // TODO This part is not used in old app
    // let description = 'Aucune description';
    // if (user.ad.description.text) {
    //   description = `${user.ad.description.text.substring(0, 110)}...`;
    // }

    makeInfoWindowEvent(
      map,
      new google.maps.InfoWindow({
        content: `<style>
                    .info-window-content .image-block a {min-width: 215px; min-height: 174px;}
                    .gm-style-iw div {overflow: visible !important;}
                    .gm-style-iw {padding-top: 6px; max-width: 260px !important;}
                    .gm-style-iw + div {display: none;}
                    .info-window-content .price-block {position: absolute; bottom: 0; background-color: rgba(60,63,64,0.9); color: #fff; padding: 7px 30px;}
                  </style>
                  <div class="info-window-content">
                    <div class="image-block">
                      <a href="${href}" style="display: block; position: relative;">
                          <img height="215" width="174" src="https://${
                            profile.photo.filename
                          }"/>
                      </a>
                    </div>
                    <div>
                      <a class="result-name" href="${href}">${
          profile.first_name
        }</a>
                      <span class="result-university">${
                        profile.school.name
                      }</span>
                    </div>
                  </div>`,
        maxWidth: 260
      }),
      marker
    );
    markers.push(marker);
  }

  google.maps.event.clearListeners(map, 'click');
  map.addListener('click', closeInfoWindow);

  return markers;

  /* Info Bulle on map */
  function makeInfoWindowEvent(map, infowindow, marker) {
    marker.addListener('click', () => {
      closeInfoWindow();
      infowindow.open(map, marker);
      infoWindow = infowindow;
    });
  }

  function closeInfoWindow() {
    if (infoWindow) {
      infoWindow.close();
      infoWindow = null;
    }
  }
};
