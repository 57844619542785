import React from 'react';
import moment from 'moment';
import axios from 'axios';

export const Image = ({ wrapperClass, isDataLoaded, ...otherProps }) => (
  <div
    className={`${
      !isDataLoaded ? 'skeleton-image ' + wrapperClass : wrapperClass
    }`}
    style={!isDataLoaded ? { padding: 0, paddingBottom: '100%' } : {}}
  >
    {isDataLoaded && <img {...otherProps} />}
  </div>
);


export const getArrayOf = number =>
  Array.apply(null, { length: number }).map(Number.call, Number);

export const decodeCustomUrl = string => string;

export const justifyName = string =>
  string
    .toLowerCase()
    .replace(/ - /g, '-')
    .replace(/- /g, '-')
    .replace(/ -/g, '-')
    .replace(/ /g, '-')
    .replace(/è/g, 'e')
    .replace(/é/g, 'e')
    .replace(/é/g, 'e')
    .replace(/ç/g, 'c')
    .trim();

export const formatDuration = (durationMin = 0) => {
  const mObj = moment().hours(0).minutes(durationMin || 0).seconds(0);
  const format = [
    mObj.hours() > 0 ? 'h[h]' : '',
    mObj.minutes() > 0 ? 'm[min]' : ''
  ].filter(i => i).join(' ');

  return mObj.format(format);
}

export const formatPrice = (price = 0) =>
  new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(price || 0);


export const setObjectPropValueByPath = (obj = {}, fieldPath = '', value = null) => {
  const pList = fieldPath.split('.');
  const key = pList.pop();
  const pointer = pList.reduce((accumulator, currentValue) => {
    if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
    return accumulator[currentValue];
  }, obj);
  pointer[key] = value;

  return obj;
}

export const generateArray = (from, to) => Array(++to - from).fill(from).map((v, i) => `${v + i}`);

export const CURRENT_YEAR = (new Date()).getFullYear();

export const MONTHS = [
  "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet",
  "Août", "Septembre", "Octobre", "Novembre", "Décembre"
];

export const formatPhoneNumber = (phoneNumber = '') => {
  const results = [];
  let pNumber = `${phoneNumber || ''}`;

  while (pNumber.length) {
      results.push(pNumber.slice(0, 2));
      pNumber = pNumber.slice(2);
  }

  return results.join(' ');
}