import * as apiService from '../services/api.service';
import { setObjectPropValueByPath } from '../utils';

export const SET_USER_CURSUS = 'userCursus/SET_CURSUS';
export const SET_USER_CURSUS_LOADER = 'userCursus/SET_LOADER';
export const SET_USER_CURSUS_SAVING_LOADER = 'userCursus/SET_SAVING_LOADER';

const initialState = {
  cursus: {},
  isLoading: false,
  isSaving: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_CURSUS:
      return {...state, cursus: {...action.cursus}};

    case SET_USER_CURSUS_LOADER:
      return {...state, isLoading: action.isLoading};

    case SET_USER_CURSUS_SAVING_LOADER:
      return {...state, isSaving: action.isSaving};

    default:
      return state;
  }
};


export const getUserCursus = userUuid => dispatch => {
  dispatch({ type: SET_USER_CURSUS, cursus: {} });
  dispatch({ type: SET_USER_CURSUS_LOADER, isLoading: true });

  return apiService.getUserCursus(userUuid)
    .then(({cursus}) => {
      dispatch({ type: SET_USER_CURSUS_LOADER, isLoading: false });

      // Adapt prepas2 -> prepas
      cursus.prepas = cursus.prepas || [];
      if (Array.isArray(cursus.prepas2)) {
        cursus.prepas = cursus.prepas2;
        delete cursus.prepas2;
      }

      return dispatch({ type: SET_USER_CURSUS, cursus });
    })
    .catch(error => {
      dispatch({ type: SET_USER_CURSUS_LOADER, isLoading: false });
      // if (typeof alert === 'function') alert(error);
    });
}

export const saveUserCursus = userUuid => (dispatch, getState) => {
  const cursus = {...(getState().userCursus.cursus || {})};

  dispatch({ type: SET_USER_CURSUS_SAVING_LOADER, isSaving: true });
  cursus.langs = (cursus.languages || []).map(lang => lang.code).join(', ');

  // Adapt prepas -> prepas2
  cursus.prepas2 = cursus.prepas;
  delete cursus.prepas;

  return apiService.setUserCursus(userUuid, cursus)
    .then(() => {
      dispatch({ type: SET_USER_CURSUS_SAVING_LOADER, isSaving: false });

      return dispatch(getUserCursus(userUuid));
    })
    .catch(error => {
      dispatch({ type: SET_USER_CURSUS_SAVING_LOADER, isSaving: false });
      // if (typeof alert === 'function') alert(error);
    });
}


export const changeCursusField = (fieldPath, value) => (dispatch, getState) => {
  const cursus = {...(getState().userCursus.cursus || {})};

  setObjectPropValueByPath(cursus, fieldPath, value);
  dispatch({ type: SET_USER_CURSUS, cursus });

  return Promise.resolve();
}

export const changeCursusArrayPropItem = (propName, index, changes) => (dispatch, getState) => {
  const cursus = {...(getState().userCursus.cursus || {})};
  const propValue = [...(cursus[propName] || [])];

  propValue[index] = {...(propValue[index] || {}), ...(changes || {})};
  dispatch({ type: SET_USER_CURSUS, cursus: {...cursus, [propName]: propValue} });

  return Promise.resolve();
}

export const addCursusArrayPropItem = (propName, newItem) => (dispatch, getState) => {
  const cursus = {...(getState().userCursus.cursus || {})};

  dispatch({
    type: SET_USER_CURSUS,
    cursus: {
      ...cursus,
      [propName]: [
        ...(cursus[propName] || []),
        newItem || {}
      ]
    }
  });

  return Promise.resolve();
}

export const removeCursusArrayPropItem = (propName, index) => (dispatch, getState) => {
  const cursus = {...(getState().userCursus.cursus || {})};
  const propValue = [...(cursus[propName] || [])];

  propValue.splice(index, 1);
  dispatch({
    type: SET_USER_CURSUS,
    cursus: {
      ...cursus,
      [propName]: propValue
    }
  });

  return Promise.resolve();
}

export const addCursusDiploma = () => dispatch => {
  return dispatch(
    addCursusArrayPropItem(
      'diplomas',
      { school: '', title: '', year: '' }
    )
  );
}

export const addCursusPrepa = () => dispatch => {
  return dispatch(
    addCursusArrayPropItem(
      'prepas',
      { high_school_name: '', specialty: 'BCPST' }
    )
  );
}

export const addCursusLanguage = code => dispatch => {
  return dispatch(
    addCursusArrayPropItem(
      'languages',
      { code, level: 'natif' }
    )
  );
}

export const selectMainDiploma = index => (dispatch, getState) => {
  const cursus = getState().userCursus.cursus || {};
  const session = getState().auth.currentUser || {};

  return apiService.setUserCursusMainDiploma(index, session.user.uuid)
    .then(response => {
      if (response.status === 'success') {
        // Update cursus state
        const diplomas = (cursus.diplomas || [])
          .map((diplom, _index) => ({
            ...diplom,
            ...{main: index === _index}
          }));

        dispatch({
          type: SET_USER_CURSUS,
          cursus: {
            ...cursus,
            diplomas
          }
        });
      }
    });
}

export const removeCursusLanguage = index => dispatch => {
  return dispatch(
    removeCursusArrayPropItem('languages', index)
  );
}

export const changeCursusLanguage = (index, changes) => dispatch => {
  return dispatch(
    changeCursusArrayPropItem('languages', index, changes)
  );
}

export const changeCursusDiploma = (index, changes) => dispatch => {
  return dispatch(
    changeCursusArrayPropItem('diplomas', index, changes)
  );
}

export const changeCursusPrepa = (index, changes) => dispatch => {
  return dispatch(
    changeCursusArrayPropItem('prepas', index, changes)
  );
}
