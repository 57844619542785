import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import {
  ROUTES, isCoursCityRoute, isCoursTopicRoute,
  isCoursTopicCityRoute, isCoursLevelRoute,
  isCoursPrivateRoute, isCoursPrivateCityRoute
} from '../../routes';
import { logoutUser } from '../../reducers';
import './header.scss';
import AuthHeaderMenu from './components/auth-header-menu';
import NotAuthHeaderMenu from './components/not-auth-header-menu';
import { HEADER_ADDITIONAL_HEIGHT } from './components/header-additional';

export const HEADER_HEIGHT = 72;
export const PHONE_NUMBER = '01 82 88 01 68';
export { HEADER_ADDITIONAL_HEIGHT };

class Header extends React.PureComponent {
  get headerClassName() {
    return [
      'navbar', 'navbar-default', 'navbar-fixed-top',
      this.isHomePage() ? 'home-header' : '',
      this.isCoursPage() ? 'cours-page-header' : '',
      this.state.isHeaderWithSubstrate ? 'visible' : ''
    ]
      .filter(item => item)
      .join(' ')
  }

  constructor(props) {
    super(props);

    this.state = {
      isHeaderWithSubstrate: false,
      menuOpened: false,
      showNumber: true,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateHeaderOffset.bind(this));
    window.addEventListener('click', this.globalClickListener.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateHeaderOffset.bind(this));
    window.removeEventListener('click', this.globalClickListener.bind(this));
  }

  globalClickListener = event => {
    const { target } = event;
    const handler = (route = null) => {
      event.preventDefault();
      this.openAuthModal({ route });
    };

    if (target.classList.contains('login-proposer')) {
      handler();
    } else if (target.classList.contains('register-proposer')) {
      handler('choiceSignUp');
    } else {
      const closestElem = target.closest('.login-proposer');
      if (closestElem && closestElem.contains(target)) {
        handler();
      }
    }
  };

  updateHeaderOffset = () => {
    const height = this.isCoursPage() ? 5 : 40;
    const nextHeaderWithSubstrateValue = window.pageYOffset > height;

    this.setState({ isHeaderWithSubstrate: nextHeaderWithSubstrateValue });
  };

  isHomePage = () => this.props.location.pathname === ROUTES.HOME;
  isStartPage = () => this.props.location.pathname === ROUTES.TUTEE_START;

  isCoursPage = () => {
    const { location: { pathname } } = this.props;

    return (
      isCoursCityRoute(pathname) ||
      isCoursTopicRoute(pathname) ||
      isCoursTopicCityRoute(pathname) ||
      isCoursLevelRoute(pathname) ||
      isCoursPrivateRoute(pathname) ||
      isCoursPrivateCityRoute(pathname)
    );
  }

  closeMenu = () => {
    this.setState({ menuOpened: false });
  }

  render() {
    const { menuOpened } = this.state;

    return (
      <header id="header" className={this.headerClassName}>
        <div>
          <div className="header-container">
            <div className="navbar-header">
              <a
                className="logo cursor-pointer"
                href="/"
              >
                Clevermate.com
              </a>

              <button
                className={`header-btn navbar-toggle`}
                aria-label="navbar toggle"
                onClick={() => this.setState({ menuOpened: !menuOpened })}
              />
            </div>

            <div
              id="header-nav"
              className={`navbar-collapse collapse navHeaderCollapse${
                menuOpened ? ' in' : ''
                }`}
            >
              {this.renderMenu()}
            </div>
          </div>
        </div>
      </header>
    );
  }

  renderMenu() {
    const { isAuthenticated, currentUser, goTo, logoutUser } = this.props;
    const isCoursPage = this.isCoursPage();
    const isStartPage = this.isStartPage();

    if (isAuthenticated && currentUser) {
      return (
        <AuthHeaderMenu
          isCoursPage={isCoursPage}
          isStartPage={isStartPage}
          goTo={goTo}
          currentUser={currentUser}
          isHomePage={() => this.isHomePage() || this.isCoursPage()}
          logoutUser={logoutUser}
        />
      );
    }

    return (
      <NotAuthHeaderMenu
        isCoursPage={isCoursPage}
        goTo={goTo}
        openAuthModal={(route = null) => {
          this.openAuthModal({ route });
        }}
        closeMenu={this.closeMenu}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ logoutUser }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
