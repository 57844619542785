import React from 'react';
import { Loader } from '../Loader';

const NextButton = ({isLoading, children, ...otherProps}) => (
  <button
    style={{color: '#fff'}}
    className={`btn next-button${isLoading ? ' disabled' : ''}`}
    disabled={isLoading}
    {...otherProps}
  >
    {isLoading
      ? <Loader lg/>
      : children
    }
  </button>
);

NextButton.defaultProps = {
  isLoading: false
}

export default NextButton;