import React, { Component, Fragment } from 'react';
import './loading-bar.scss';

class LoadingBar extends Component {
  constructor(props) {
    super(props);

    this.state = { width: 1 };
  }

  componentDidMount() {
    this.start();
  }

  componentWillUnmount() {
    this.clearTimer();
    // this.complete();
  }

  start = () => {
    this.set(30);
    this.repeatInc();

    // setTimeout(() => {
    //   this.set(30);
    //
    //   setTimeout(() => {
    //     this.set(60);
    //     this.repeatInc();
    //   }, 150);
    // }, 50);
  };

  set = (width = 1) => this.setState({ width: width });

  complete = () => {
    this.clearTimer();
    this.set(100);
  };

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  inc = () => {
    const { width } = this.state;
    const rand = Math.random();
    let _inc = 0;

    if (width < 20) _inc = rand * 30;
    else if (width < 50) _inc = rand * 20;
    else if (width < 80) _inc = rand * 10;
    else if (width < 95) _inc = rand;

    this.setState({
      width: width + (Math.round(Math.random()) === 1 ? _inc : 0)
    });
  };

  repeatInc = () => {
    this.timer = setTimeout(() => {
      this.inc();
      this.repeatInc();
    }, 300);
  };

  render() {
    const { width } = this.state;

    return (
      <Fragment>
        <div id="loading-bar-spinner">
          <div className="spinner-icon" />
        </div>

        <div id="loading-bar">
          <div className="bar" style={{ width: `${width || 1}%` }}>
            <div className="peg" />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LoadingBar;
