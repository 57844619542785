import React, { Fragment } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { frontloadConnect } from 'react-frontload';
import AdminTuteeAccountPage from '../../../../components/AdminTuteeAccountPage';
import TextInput from '../../../../components/TextInput';
import NextButton from '../../../../components/NextButton';
import { LoaderContainer } from '../../../../components/Loader';
import { getTuteeAttestations } from '../../../../../reducers';
import { formatPrice } from '../../../../../utils';
import moment from 'moment';

import {generateTuteeAttestation} from '../../../../../utils/reports'

const frontload = async props => await props.getTuteeAttestations();

class AccountPersonalAttestations extends React.Component {

  handleDownload(item){
      generateTuteeAttestation(item)
  }
  render() {
    const { location, goTo, isLoading, attestations, session, tutee } = this.props;

    return (
      <AdminTuteeAccountPage
        title="Tutee Attestations"
        location={location}
        goTo={goTo}
        session={session}
        tutee={tutee}
        page={'factures'}
        isSidebarVisible={false}
      >
        {isLoading && <LoaderContainer/>}

        {!isLoading && (
          <Fragment>
            <h4 style={{marginTop:20}}>Historique des attestations</h4>

            <div className="o-x-auto ml-2">
              <table className="table table-striped" style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th style={{fontWeight:'bold'}}>Année</th>
                    <th style={{fontWeight:'bold'}}>Montant</th>
                    <th style={{fontWeight:'bold'}}>Attestations</th>
                  </tr>
                </thead>

                <tbody>
                  {attestations && attestations.length > 0 && attestations.map((item, index) => (
                    <tr key={`attestation-${index}`}>
                      <td>{item.year}</td>
                      <td>{formatPrice(item.total_amount)}</td>
                      <td>
                        <p style={{color: '#135a8f', cursor: 'pointer'}} onClick={this.handleDownload.bind(this, item)} >
                          <img style={{
                            bottom: 2,
                            position: "relative",
                            right: 3
                          }} alt='download' src='https://cdn-icons-png.flaticon.com/512/11163/11163429.png' width={20} height={20}/>
                          Attestation fiscale
                        </p>
                      </td>
                    </tr>
                  ))}

                  {(!attestations || attestations.length === 0) && (
                    <tr>
                      <td colSpan="100" className="text-center">Aucune attestation disponible</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Fragment>
        )}
      </AdminTuteeAccountPage>
    );
  }
};

const mapStateToProps = state => ({
  session: state.auth.currentUser,
  attestations: state.tutee.attestations,
  isLoading: state.tutee.isAttestationsLoading,
  tutee: state.tutee.tutee,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ getTuteeAttestations }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(AccountPersonalAttestations)
);
