import React from 'react';
import { ADMIN_ROUTES } from '../../../../../../admin/routes';
import RouterLink from '../../../../../router-link';

export default () => (
  <li className="send-course-wrapper">
    <RouterLink
      className="send-course"
      route={ADMIN_ROUTES.TUTOR_ACCOUNT_INFOCARD}
    >
      Proposer un cours
    </RouterLink>
  </li>
)