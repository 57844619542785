import React, { Component } from 'react';
import Helmet from 'react-helmet';
import * as metadata from './metadata';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { establishCurrentUser, getUserTypes } from './reducers';
import LoadingBar from './components/loading-bar';
import { initFB } from './services/fb.service';
import { callAnalytics } from './services/api.service';
import Header from './components/header';
import HeaderAdditional from './components/header/components/header-additional';
import Footer from './components/footer';
import Routes from './routes';
import { isServer } from './store';
import { frontloadConnect } from 'react-frontload';
import { isAdminPage as isAdminPageCheck } from './admin/routes';
import * as ReactToastr from 'react-toastr';
import moment from 'moment';
import 'moment/locale/fr';
import { useLocation } from 'react-router-dom';

moment.locale('fr');

const { ToastContainer } = ReactToastr;
const ToastMessageFactory = React.createFactory(ReactToastr.ToastMessage.animation);
const frontload = async props => {
  if (!props.isUserTypesLoaded) await props.getUserTypes();
};

class App extends Component {
  constructor(props) {
    super(props);

    this.toastrRef = React.createRef();
  }

  componentDidMount() {
    if (!isServer) {
      this.props.establishCurrentUser();

      setTimeout(() => {
        initFB();
      }, 1000)
      callAnalytics();
    }

    // load styles async
    if (typeof window !== 'undefined') {
      const resource = document.createElement('link');

      resource.setAttribute("rel", "stylesheet");
      resource.setAttribute("href", "https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css");
      resource.setAttribute("crossorigin", "anonymous");
      // resource.setAttribute("media", "bogus");

      const head = document.getElementsByTagName('head')[0];

      head.appendChild(resource);
    }

    // error handler to send errors via API
    if (typeof window !== 'undefined') {
      window.onerror = function (msg, url, lineNo, columnNo, error) {
        let analyticsUrl =
          `${process.env.BASE_URL_V3}/analytics`;
        analyticsUrl +=
          '?msg=' + b64EncodeUnicode(msg.substring(0, 1500)) + '&url=' + url;
        let xmlHttp = new XMLHttpRequest();
        xmlHttp.open('GET', analyticsUrl, false);
        xmlHttp.send(null);

        function b64EncodeUnicode(str) {
          return btoa(
            encodeURIComponent(str).replace(
              /%([0-9A-F]{2})/g,
              function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
              }
            )
          );
        }

        return false;
      };
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      callAnalytics();
    }
  }

  componentDidUpdate(prevProps) {
    // Scroll to top on page switch
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  showAlert = (title = '', msg = '', type = 'success', options = {}) => {
    if (!this.toastrRef || !this.toastrRef.current || !this.toastrRef.current[type]) {
      return;
    }

    this.toastrRef.current[type](
      msg,
      title,
      {
        timeOut: 4000,
        extendedTimeOut: 2000,
        showAnimation: `animated fadeIn`,
        hideAnimation: `animated fadeOut`,
        ...(options || {})
      }
    );
  }

  render() {
    const {
      isUsersLoading,
      isProfileLoading,
      isStaticPageLoading,
      isCoursCityLoading,
      isCoursTopicLoading,
      isCoursTopicCityLoading,
      isPrivateCoursCityLoading,
      isSeoPageLoading,
      location: {pathname} = {}
    } = this.props;
    const isVisibleLoadingBar =
      isUsersLoading ||
      isProfileLoading ||
      isStaticPageLoading ||
      isCoursCityLoading ||
      isCoursTopicLoading ||
      isCoursTopicCityLoading ||
      isPrivateCoursCityLoading ||
      isSeoPageLoading;
    const isAdminPage = isAdminPageCheck(pathname);
    const isVisibleFooter = !isAdminPage;

    

    return (
      <div id="app" className="app">
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          title={metadata.getDefaultTitle()}
          meta={metadata.getMetaTags()}
          link={metadata.link}
          script={metadata.script}
          noscript={metadata.noscript}
        >
<script defer src={`https://${process.env.NEXT_PUBLIC_DOMAIN_UMAMI}/script.js`} data-website-id={process.env.NEXT_PUBLIC_UMAMI_SITE_ID}></script>    
<script defer src={`https://${process.env.NEXT_PUBLIC_DOMAIN_UMAMI}/script.js`} data-website-id={process.env.NEXT_PUBLIC_UMAMI_SITE_ID_G}></script>    
<script src="https://unpkg.com/iban@0.0.14/iban.js"></script>
    </Helmet>
        {/* <CloudimageProvider config={{ token: process.env.AIRSTORE_CONTAINER, baseURL: process.env.NEXT_PUBLIC_IMAGES_BASE_URL }}> */}
          <Header
            isAuthenticated={this.props.isAuthenticated}
            current={pathname}
          />

          <HeaderAdditional {...this.props} />

          <div className={`flex-expander${isAdminPage ? ' admin-page' : ''}`}>
            <Routes showAlert={this.showAlert} />
          </div>

          {isVisibleFooter && <Footer />}
        {/* </CloudimageProvider> */}

        {isVisibleLoadingBar && <LoadingBar />}

        <ToastContainer
          ref={this.toastrRef}
          toastMessageFactory={ToastMessageFactory}
          className="toast-top-right"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isUsersLoading: state.search.isLoading,
  isUserTypesLoaded: state.search.isUserTypesLoaded,
  isProfileLoading: state.profile.isLoading,
  isStaticPageLoading: state.staticPage.isStaticPageLoading,
  isCoursCityLoading: state.cours.isCoursCityLoading,
  isCoursTopicLoading: state.cours.isCoursTopicLoading,
  isCoursTopicCityLoading: state.cours.isCoursTopicCityLoading,
  isPrivateCoursCityLoading: state.privateCours.isCityLoading,
  isSeoPageLoading: state.seoPage.isSeoPageLoading,
  pathname: state.router.location.pathname
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ establishCurrentUser, getUserTypes }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    frontloadConnect(frontload, {
      onMount: true,
      onUpdate: false
    })(App)
  )
);
