import { getUser } from '../services/api.service';

export const SET_CURRENT_PROFILE = 'profile/SET_CURRENT_PROFILE';
export const SET_PROFILE_LOADER = 'profile/SET_LOADER';

const initialState = {
  currentUser: {},
  currentProfile: {},
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PROFILE:
      return {
        ...state,
        currentUser: action.user,
        currentProfile: action.user.profile ? action.user.profile : {}
      };

    case SET_PROFILE_LOADER:
      return { ...state, isLoading: action.isLoading || false };

    default:
      return state;
  }
};

export const getCurrentUser = id => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_PROFILE_LOADER, isLoading: true });

    getUser(id)
      .then(response => response && response.user)
      .then((user = {}) => {
        user.profile = user.profile || {};

        dispatch({ type: SET_CURRENT_PROFILE, user });
        dispatch({ type: SET_PROFILE_LOADER, isLoading: false });

        resolve(user);
      })
      .catch(error => {
        dispatch({ type: SET_PROFILE_LOADER, isLoading: false });
        console.log(error);
        // if (typeof alert === 'function') alert(error);
        resolve(error);
      });
  });

export const removeCurrentUser = () => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_CURRENT_PROFILE, user: {} });

    resolve({});
  });
