import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTES } from '../../../../routes';
import RouterLink from '../../../router-link';
import StaticRouterLink from '../../../static-router-link';

function NotAuthHeaderMenu({ closeMenu }) {
  const  history = useHistory();

  function privateClick(e) {
    () => closeMenu()
    e.preventDefault();
    if (history.location.pathname.includes('/donner-cours-particuliers')) {
      history.push('/sign-up#signupTutor');
    } else {
      history.push('/donner-cours-particuliers');
    }
  }

  return <ul className={`cl-navbar-nav nav navbar-nav navbar-right`}>
    {/* {!isCoursPage && (
      <li className="active-link">
        <a className="phone-link" href="" onClick={ev => ev.preventDefault()}>
          <i className="fa fa-phone" aria-hidden="true" />&nbsp;{PHONE_NUMBER}
        </a>
      </li>
    )} */}

    {/* {!isCoursPage && (
      <li className="active-link">
        <a
          href={ROUTES.SCHOOLS}
          onClick={event => {
            event.preventDefault();
            goTo(ROUTES.SCHOOLS);
          }}
        >
          Les écoles
        </a>
      </li>
    )} */}

    <li onClick={() => closeMenu()}>
      <Link to={ROUTES.SIGN_UP}>Connexion</Link>
    </li>

    <li onClick={() => closeMenu()}>
      <StaticRouterLink route="/contact">Aide</StaticRouterLink>
    </li>

    <li className="send-course-wrapper">
      <a
        className="send-course"
        onClick={privateClick}
      >
        Donner des cours{' '}
      </a>
    </li>
  </ul >
}


NotAuthHeaderMenu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
}

export default NotAuthHeaderMenu;