import * as apiService from '../services/api.service';

export const SET_USER_TOPICS_PRICES = 'userTopics/SET_TOPICS_PRICES';
export const SET_USER_TOPICS_LOADER = 'userTopics/SET_LOADER';
export const SET_USER_TOPICS_SAVING_LOADER = 'userTopics/SET_SAVING_LOADER';

const initialState = {
  topicsPrices: {},
  isLoading: false,
  isSaving: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_TOPICS_PRICES:
      return {...state, topicsPrices: {...action.topicsPrices}};

    case SET_USER_TOPICS_LOADER:
      return {...state, isLoading: action.isLoading};

    case SET_USER_TOPICS_SAVING_LOADER:
      return {...state, isSaving: action.isSaving};

    default:
      return state;
  }
};


export const getUserTopicsPrices = (userUuid) => dispatch => {
  dispatch({ type: SET_USER_TOPICS_PRICES, topicsPrices: {} });
  dispatch({ type: SET_USER_TOPICS_LOADER, isLoading: true });

  return apiService.getUserTopicsPrices(userUuid)
    .then((response) => {
      let topicsPrices = {};

      if (response && response['topics-prices']) {
        topicsPrices = response['topics-prices'];
      }

      // Adapt topics2 -> topics
      if (topicsPrices && topicsPrices.topics2) {
        topicsPrices.topics = topicsPrices.topics2;
        delete topicsPrices.topics2;
      }

      dispatch({ type: SET_USER_TOPICS_LOADER, isLoading: false });
      return dispatch({ type: SET_USER_TOPICS_PRICES, topicsPrices });
    })
    .catch(error => {
      dispatch({ type: SET_USER_TOPICS_LOADER, isLoading: false });
      // if (typeof alert === 'function') alert(error);
    });
}

export const saveUserTopicsPrices = userUuid => (dispatch, getState) => {
  const topicsPrices = getState().userTopics.topicsPrices || {};

  dispatch({ type: SET_USER_TOPICS_SAVING_LOADER, isSaving: true });

  // Adapt topics -> topics2
  if (topicsPrices && topicsPrices.topics) {
    let filtredTopics = topicsPrices.topics.filter(topic=>Object.keys(topic).includes('key') && typeof topic.key === 'string' && topic.key.trim() !== '');
    topicsPrices.topics2 = filtredTopics;
    delete topicsPrices.topics;
  }

  
  return apiService.setUserTopicsPrices(userUuid, topicsPrices)
    .then(() => {
      dispatch({ type: SET_USER_TOPICS_SAVING_LOADER, isSaving: false });

      return dispatch(getUserTopicsPrices(userUuid));
    })
    .catch(error => {
      dispatch({ type: SET_USER_TOPICS_SAVING_LOADER, isSaving: false });
      // if (typeof alert === 'function') alert(error);
    });
}

export const changeUserTopic = (key, changes,delete_default_value = true) => (dispatch, getState) => {
  const topicsPrices = {...(getState().userTopics.topicsPrices || {})};

  if (topicsPrices && topicsPrices.topics) {

    const topics = [...(topicsPrices.topics || [])];

    //delete default value
    if(delete_default_value){
      let default_value_index = topics.findIndex(t => (t.name == '' && !t.main))
      if (default_value_index > -1){
        topics.splice(default_value_index,1);
      }
    }

    let topic = topics.find(topic => topic.key == key);

    if (topic) {
      Object.assign(topic, changes || {});
      topic.level = topic.level || 1;
      dispatch({ type: SET_USER_TOPICS_PRICES, topicsPrices: {...topicsPrices, topics} });
    }
  }

  return Promise.resolve();
}

export const addUserTopic = (main = false) => (dispatch, getState) => {
  const topicsPrices = {...(getState().userTopics.topicsPrices || {})};

  if (topicsPrices && topicsPrices.topics) {
    const topics = [...(topicsPrices.topics || [])];
    const topicIndex = topics.findIndex(t => !t.active);

  /*   if (topicIndex > -1) { */
     /*  const [topic] = topics.splice(topicIndex, 1);

      Object.assign(topic, {active: true, main});

      topic.level = topic.level || 1;

      topics.push(topic); */

      topics.push({key: Math.random(), name: '', active: true, level: 0, main}); // Cut & Push to an array to see just added topic at the end of the list in view

      dispatch({ type: SET_USER_TOPICS_PRICES, topicsPrices: {...topicsPrices, topics} });
   /*  } */
  }

  return Promise.resolve();
}

export const removeTopic = key => dispatch => {
  dispatch(changeUserTopic(key, {active: false, main: false}));

  return Promise.resolve();
}
