import React from 'react';
import clsx from 'clsx';
import { ADMIN_ROUTES, ADMIN_TUTEE_ACCOUNT_ROUTES, ADMIN_TUTEE_COURSE_MONITORING_ROUTES } from '../../routes';
import RouterLink from '../../../components/router-link';

class TuteeTopbar extends React.Component {
  render() {
    const { currentUser, pathname, session } = this.props;
    const isAccountRoute = Object.keys(ADMIN_TUTEE_ACCOUNT_ROUTES)
      .map(key => ADMIN_TUTEE_ACCOUNT_ROUTES[key])
      .some(routePath => pathname === routePath);
    const isCourseMonitoringRoute = Object.keys(ADMIN_TUTEE_COURSE_MONITORING_ROUTES)
      .map(key => ADMIN_TUTEE_COURSE_MONITORING_ROUTES[key])
      .some(routePath => pathname === routePath);

    return (
      <ul id="cv-user-topbar" className="nav nav-tabs">
          <li className={clsx('color-tab-active', {active: isCourseMonitoringRoute})}>
          <RouterLink route={ADMIN_ROUTES.TUTEE_COURSE_MONITORING_PLANNING}>Mes cours</RouterLink>
        </li>

        <li className={clsx('color-tab-active', {active: pathname === ADMIN_ROUTES.TUTEE_PAUSE})}>
          <RouterLink route={ADMIN_ROUTES.TUTEE_PAUSE}>Suspendre</RouterLink>
        </li>

        <li className={clsx('color-tab-active', {active: pathname === ADMIN_ROUTES.TUTEE_ACCOUNT_INVOICES || pathname === ADMIN_ROUTES.TUTEE_ACCOUNT_AF})}>
          <RouterLink route={ADMIN_ROUTES.TUTEE_ACCOUNT_INVOICES}>Factures</RouterLink>
        </li>
        
        <li className={clsx('color-tab-active', {active: pathname === ADMIN_ROUTES.TUTEE_REFERRAL})}>
          <RouterLink route={ADMIN_ROUTES.TUTEE_REFERRAL}>Parrainer</RouterLink>
        </li>

        <li className={clsx('color-tab-active', {active: pathname === ADMIN_ROUTES.TUTEE_SOUTENIR})}>
          <RouterLink route={ADMIN_ROUTES.TUTEE_SOUTENIR}>Nous soutenir</RouterLink>
        </li>

      
        <li className={clsx('color-tab-active', {active: pathname === ADMIN_ROUTES.TUTEE_AIDE})}>
          <RouterLink route={ADMIN_ROUTES.TUTEE_AIDE}>Aide</RouterLink>
        </li>

        <li className={clsx('color-tab-active', {active: isAccountRoute})}>
          <RouterLink route={ADMIN_ROUTES.TUTEE_ACCOUNT_PERSONAL_INFO}>Mon compte</RouterLink>
        </li>

        {/* <li className={clsx('color-tab-active', {active: pathname === ADMIN_ROUTES.TUTEE_HOW_IT_WORKS})}>
          <RouterLink route={ADMIN_ROUTES.TUTEE_HOW_IT_WORKS}>Comment ça marche?</RouterLink>
        </li>

        <li className={clsx('color-tab-active', {active: pathname === ADMIN_ROUTES.TUTEE_PROPOSAL})}>
          <RouterLink route={ADMIN_ROUTES.TUTEE_PROPOSAL}>Nous aider</RouterLink>
        </li> */}

      

        {/* From spec: when client, the tab COMMENCER should disappear */}
        {(currentUser || {}).customer_type !== 'client' && (currentUser || {}).customer_type !== 'Client' && (
          <li className={clsx('color-tab-active', {active: pathname === ADMIN_ROUTES.TUTEE_START})}>
            <RouterLink route={ADMIN_ROUTES.TUTEE_START}>COMMENCER</RouterLink>
          </li>
        )}
      </ul>
    );
  }
};

export default TuteeTopbar;