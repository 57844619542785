import React, { Fragment } from 'react';
import { redirectToAuthApp } from '../../../../services/redirect.service';
import BlogMenuItem from './components/blog-menu-item';
import SchoolsMenuItem from './components/schools-menu-item';
import UserDropdownMenuItem from './components/user-dropdown-menu-item';
import MessagerMenuItem from './components/messager-menu-item';
import SubmitCourseMenuItem from './components/submit-course-menu-item';

class AuthHeaderMenu extends React.Component {
  logout = () => {
    this.props.logoutUser(true);
  }

  redirectToAuthApp = redirect => {
    redirectToAuthApp({session: this.props.currentUser, redirect})
  }

  render() {
    const { currentUser, goTo, isCoursPage, isStartPage } = this.props;
    const isTutor = currentUser && currentUser.user_tutor === 1;

    return (
      <ul className="nav navbar-nav navbar-right">
        {!isCoursPage && !isStartPage && (
          <Fragment>
            <BlogMenuItem />
            {/* <SchoolsMenuItem goTo={goTo} /> */}
          </Fragment>
        )}

        <UserDropdownMenuItem
          currentUser={currentUser}
          isStartPage={isStartPage}
          redirectToAuthApp={this.redirectToAuthApp.bind(this)}
          logout={this.logout.bind(this)}
        />

        {/* <MessagerMenuItem
          currentUser={currentUser}
          redirectToAuthApp={this.redirectToAuthApp.bind(this)}
        /> */}

        {/* {isTutor && (
          <SubmitCourseMenuItem />
        )} */}
      </ul>
    );
  }
}

export default AuthHeaderMenu;



// import React from 'react';
// import { ROUTES } from '../../../../routes';
// import { redirectToAuthApp } from '../../../../services/redirect.service';
// import { ADMIN_ROUTES } from '../../../../admin/routes';

// class AuthHeaderMenu extends React.Component {
//   constructor(props) {
//     super(props);

//     this.userDropdownRef = React.createRef();
//     this.state = { dropdownOpened: false };

//     window.addEventListener('click', this.globalClickHandler);
//   }

//   componentWillUnmount() {
//     window.removeEventListener('click', this.globalClickHandler);
//   }

//   globalClickHandler = ({ target }) => {
//     if (
//       this.state.dropdownOpened &&
//       !this.userDropdownRef.current.contains(target)
//     ) {
//       this.closeDropdown();
//     }
//   };

//   logout = () => {
//     this.props.logoutUser(true);
//   };

//   redirectClickHandler = (redirect, ev) => {
//     redirectToAuthApp({session: this.props.currentUser, redirect});
//     ev.preventDefault();
//   }

//   goToClickHandler = (route, ev) => {
//     this.closeDropdown();
//     this.props.goTo(route);
//     ev.preventDefault();
//   }

//   closeDropdown = () => {
//     this.setState({dropdownOpened: false});
//   }

//   dropdownToggleClickHandler = (ev) => {
//     this.setState(({dropdownOpened}) => ({dropdownOpened: !dropdownOpened}));
//     ev.preventDefault();
//   }

//   noopClickHandler = ev => ev.preventDefault();

//   render() {
//     const { dropdownOpened } = this.state;
//     const { currentUser, isHomePage } = this.props;
//     const prospect = currentUser.customer_type === 'prospect';

//     return (
//       <ul className="nav navbar-nav navbar-right">
//         <li className="active-link">
//           <a target="_blank" href={process.env.MAGAZINE_URL}>
//             Blog
//           </a>
//         </li>
//         <li className="active-link">
//           <a
//             href={ROUTES.SCHOOLS}
//             onClick={this.goToClickHandler.bind(this, ROUTES.SCHOOLS)}
//           >
//             Les écoles
//           </a>
//         </li>

//         <li
//           className={`dropdown ${dropdownOpened ? 'open' : ''}`}
//           ref={this.userDropdownRef}
//         >
//           <a
//             href="javascript:void(0)"
//             className="dropdown-toggle"
//             onClick={this.dropdownToggleClickHandler}
//           >
//             <i className="fa fa-gear" /> {currentUser.first_name}{' '}
//             <span className="caret" />
//           </a>

//           {currentUser.user_tutor === 1 && (
//             <ul className="dropdown-menu">
//               {/*!isHomePage() &&*/ currentUser.is_fully_validated && (
//                 <li>
//                   <a
//                     href={ROUTES.TUTOR_DASHBOARD}
//                     onClick={this.goToClickHandler.bind(this, ROUTES.TUTOR_DASHBOARD)}
//                   >
//                     <i className="fa fa-tachometer" /> Tableau de bord
//                   </a>
//                 </li>
//               )}
//               {currentUser.is_fully_validated && (
//                 <li>
//                   <a
//                     href={ADMIN_ROUTES.TUTOR_ACCOUNT_INFOCARD}
//                     onClick={this.goToClickHandler.bind(this, ADMIN_ROUTES.TUTOR_ACCOUNT_INFOCARD)}
//                   >
//                     <i className="fa fa-user" /> Mon profil
//                   </a>
//                 </li>
//               )}
//               {currentUser.is_fully_validated && (
//                 <li>
//                   <a
//                     href={ADMIN_ROUTES.TUTOR_REPORTS_COURSE}
//                     onClick={this.goToClickHandler.bind(this, ADMIN_ROUTES.TUTOR_REPORTS_COURSE)}
//                   >
//                     <i className="fa fa-list-alt" /> Mes réservations
//                   </a>
//                 </li>
//               )}
//               {!currentUser.is_fully_validated && (
//                 <li>
//                   <a
//                     href="/validation"
//                     onClick={this.redirectClickHandler.bind(this, '/validation')}
//                   >
//                     <i className="fa fa-list-alt" /> Valider mon compte
//                   </a>
//                 </li>
//               )}
//               <li onClick={this.logout}>
//                 <a href="javascript:void(0)" onClick={this.noopClickHandler}>
//                   <i className="fa fa-power-off" /> Déconnexion
//                 </a>
//               </li>
//             </ul>
//           )}

//           {currentUser.user_tutor !== 1 && (
//             <ul className="dropdown-menu">
//               <li>
//                 <a
//                   href="/tutee/profile"
//                   onClick={this.redirectClickHandler.bind(this, '/tutee/profile')}
//                 >
//                   <i className="fa fa-user" /> Mon compte
//                 </a>
//               </li>
//               {prospect && (
//                 <li>
//                   <a
//                     href="/tutee/commencer"
//                     onClick={this.redirectClickHandler.bind(this, '/tutee/commencer')}
//                   >
//                     <i className="fa fa-bookmark" /> Souscrire
//                   </a>
//                 </li>
//               )}
//               <li onClick={this.logout}>
//                 <a href="javascript:void(0)" onClick={this.noopClickHandler}>
//                   <i className="fa fa-power-off" /> Déconnexion
//                 </a>
//               </li>
//             </ul>
//           )}
//         </li>

//         <li>
//           {currentUser.user_tutor === 1 &&
//             currentUser.is_fully_validated && (
//               <a
//                 href={ROUTES.TUTOR_MAIL_BOX}
//                 onClick={this.goToClickHandler.bind(this, ROUTES.TUTOR_MAIL_BOX)}
//               >
//                 <span className="visible-xs">
//                   <span>
//                     <i className="fa fa-envelope-o" /> Messagerie{' '}
//                   </span>
//                   {currentUser.new_message_count > 0 && (
//                     <span className="badge">
//                       {currentUser.new_message_count}
//                     </span>
//                   )}
//                 </span>

//                 <span className="hidden-xs">
//                   <i className="fa fa-envelope-o" />{' '}
//                   {currentUser.new_message_count > 0 && (
//                     <span className="badge">
//                       {currentUser.new_message_count}
//                     </span>
//                   )}
//                 </span>
//               </a>
//             )}

//           {currentUser.user_tutor !== 1 && (
//             <a
//               href="/tutee/messagerie?conversation"
//               onClick={this.redirectClickHandler.bind(this, '/tutee/messagerie?conversation')}
//             >
//               <span className="visible-xs">
//                 <span>
//                   <i className="fa fa-envelope-o" /> Messagerie
//                 </span>
//                 {currentUser.new_message_count > 0 && (
//                   <span className="badge">{currentUser.new_message_count}</span>
//                 )}
//               </span>
//               <span className="hidden-xs">
//                 <i className="fa fa-envelope-o" />
//                 {currentUser.new_message_count > 0 && (
//                   <span className="badge">{currentUser.new_message_count}</span>
//                 )}
//               </span>
//             </a>
//           )}
//         </li>

//         {currentUser.user_tutor === 1 && (
//           <li>
//             <a
//               className="send-course"
//               href={ADMIN_ROUTES.TUTOR_ACCOUNT_INFOCARD}
//               onClick={this.goToClickHandler.bind(this, ADMIN_ROUTES.TUTOR_ACCOUNT_INFOCARD)}
//             >
//               Proposer un cours
//             </a>
//           </li>
//         )}
//       </ul>
//     );
//   }
// }