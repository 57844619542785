import * as API from '../services/api.service';

export const SET_PRIVATE_COURS_CITY = 'privateCours/SET_CITY';
export const SET_PRIVATE_COURS_CITIES_AROUND = 'privateCours/SET_CITIES_AROUND';
export const SET_PRIVATE_COURS_CITY_LOADER = 'privateCours/SET_CITY_LOADER';
export const SET_PRIVATE_COURS_FAQ = 'privateCours/SET_FAQ';

const initialState = {
  city: {},
  citiesAround: [],
  faq: [],
  isCityLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PRIVATE_COURS_CITY:
      return {
        ...state,
        city: action.city
      };

    case SET_PRIVATE_COURS_CITIES_AROUND:
      return {
        ...state,
        citiesAround: action.citiesAround
      };

    case SET_PRIVATE_COURS_FAQ:
      return {
        ...state,
        faq: action.faq
      };

    case SET_PRIVATE_COURS_CITY_LOADER:
      return {
        ...state,
        isCityLoading: action.value
      };

    default:
      return state;
  }
};

export const getPrivateCoursCity = cityName => dispatch => {
  dispatch(setPrivateCoursCity({}));
  dispatch(setPrivateCoursCitiesAround([]));

  return new Promise(resolve => {
    dispatch(setPrivateCoursCityLoader(true));

    API.getPrivateCoursCity(cityName)
      .then(({tests, cities_around}) => {
        dispatch(setPrivateCoursCity(tests || {}));
        dispatch(setPrivateCoursCitiesAround(cities_around || []));
        dispatch(setPrivateCoursCityLoader(false));
        resolve();
      })
      .catch(() => {
        dispatch(setPrivateCoursCityLoader(false));
        resolve();
      });
  });
}

export const getPrivateCoursFaq = () => dispatch => {
  setPrivateCoursFaq([]);

  return API.getPrivateCoursFaq()
    .then(({questions}) => dispatch(setPrivateCoursFaq(questions || [])));
  }

export const setPrivateCoursCityLoader = value => ({
  type: SET_PRIVATE_COURS_CITY_LOADER,
  value
});

export const setPrivateCoursCity = city => ({
  type: SET_PRIVATE_COURS_CITY,
  city
});

export const setPrivateCoursCitiesAround = citiesAround => ({
  type: SET_PRIVATE_COURS_CITIES_AROUND,
  citiesAround
});

export const setPrivateCoursFaq = faq => ({
  type: SET_PRIVATE_COURS_FAQ,
  faq
});
