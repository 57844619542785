import * as apiService from '../services/api.service';

export const SET_CONVERSATIONS = 'conversations/SET_CONVERSATIONS';
export const SET_ACTIVE_CONVERSATION = 'conversations/SET_ACTIVE_CONVERSATION';
export const SET_CONVERSATIONS_LOADER = 'conversations/SET_LOADER';
export const SET_ACTIVE_CONVERSATION_LOADER = 'conversations/SET_ACTIVE_CONVERSATION_LOADER';

const initialState = {
  conversations: [],
  activeConversation: {uuid: null, messages: []},
  isConversationsLoading: false,
  isActiveConversationLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONVERSATIONS:
      return {...state, conversations: [...action.conversations]};

    case SET_ACTIVE_CONVERSATION:
      return {...state, activeConversation: {uuid: null, messages: [], ...action.conversation}};

    case SET_CONVERSATIONS_LOADER:
      return {...state, isConversationsLoading: action.isLoading};

    case SET_ACTIVE_CONVERSATION_LOADER:
      return {...state, isActiveConversationLoading: action.isLoading};

    default:
      return state;
  }
};


export const activateConversation = ({
  uuid = null,
  is_new_message = false
}) => dispatch => {
  dispatch({ type: SET_ACTIVE_CONVERSATION, conversation: {uuid: null, messages: []} });
  dispatch({ type: SET_ACTIVE_CONVERSATION_LOADER, isLoading: true });

  const getConversation = () => apiService.getConversation(uuid)
    .then(({conversation: messages = []}) => {
      dispatch({ type: SET_ACTIVE_CONVERSATION_LOADER, isLoading: false });
      return dispatch({ type: SET_ACTIVE_CONVERSATION, conversation: {uuid, messages} });
    })
    .catch(error => {
      dispatch({ type: SET_ACTIVE_CONVERSATION_LOADER, isLoading: false });
      // if (typeof alert === 'function') alert(error);
    });

  return is_new_message
    ? apiService.readConversation(uuid).then(getConversation, getConversation)
    : getConversation()
}

export const getConversations = (user_uuid, active_conversation_uuid = null) => dispatch => {
  dispatch({ type: SET_CONVERSATIONS, conversations: [] });
  dispatch({ type: SET_CONVERSATIONS_LOADER, isLoading: true });

  return apiService.getConversations(user_uuid)
    .then(({conversations = []}) => {
      dispatch({ type: SET_CONVERSATIONS_LOADER, isLoading: false });

      let activeConversation = null;
      if (active_conversation_uuid) {
        activeConversation = conversations.find(({uuid}) => uuid === active_conversation_uuid);
      }
      if (!activeConversation && conversations && conversations[0] && conversations[0]) {
        activeConversation = conversations[0];
      }
      if (activeConversation) {
        dispatch(activateConversation(activeConversation));
      }

      return dispatch({ type: SET_CONVERSATIONS, conversations });
    })
    .catch(error => {
      dispatch({ type: SET_CONVERSATIONS_LOADER, isLoading: false });
      // if (typeof alert === 'function') alert(error);
    });
}

export const sendMessage = (message, user_uuid, conversation) => dispatch => {
  return apiService.sendMessage(message)
    .then(() => dispatch(getConversations(user_uuid, (conversation || {}).uuid)));
}
