import attestations from '../admin/routes/tutee/account/attestations';
import {
  getTuteeRequest, getTuteeCardInfoRequest, setTuteeRequest, getTuteeInvoicesRequest,
  getTuteeAttestationsRequest,
  createTuteeStripeIntentRequest, getTuteeReportRequest, setTuteePaymentRequest, // attachTuteeStripeCardRequest
} from '../services/api.service';

export const TUTEE_SET = 'tutee/SET';
export const TUTEE_SET_INVOICES = 'tutee/SET_INVOICES';
export const TUTEE_SET_ATTESTATIONS = 'tutee/SET_ATTESTATIONS';
export const TUTEE_SET_CARD_INFO = 'tutee/SET_CARD_INFO';
export const TUTEE_SET_STRIPE_INTENT = 'tutee/SET_STRIPE_INTENT';
export const TUTEE_SET_LESSONS_NEXT = 'tutee/SET_LESSONS_NEXT';
export const TUTEE_SET_LESSONS_PAST = 'tutee/SET_LESSONS_PAST';
export const TUTEE_SET_LOADER = 'tutee/SET_LOADER';
export const TUTEE_SET_SAVE_LOADER = 'tutee/SET_SAVE_LOADER';
export const TUTEE_SET_PAYMENT_SAVE_LOADER = 'tutee/SET_PAYMENT_SAVE_LOADER';
export const TUTEE_SET_INVOICES_LOADER = 'tutee/SET_INVOICES_LOADER';
export const TUTEE_SET_ATTESTATIONS_LOADER = 'tutee/SET_ATTESTATIONS_LOADER';
export const TUTEE_SET_CARD_INFO_LOADER = 'tutee/SET_CARD_INFO_LOADER';
export const TUTEE_SET_STRIPE_INTENT_LOADER = 'tutee/SET_STRIPE_INTENT_LOADER';
export const TUTEE_SET_REPORT_LOADER = 'tutee/SET_REPORT_LOADER';

const initialState = {
  tutee: {},
  invoices: [],
  attestations: [],
  card: {},
  stripeIntent: {},
  lessonsNext: [],
  lessonsPast: [],
  isLoading: false,
  isSaving: false,
  isPaymentSaving: false,
  isInvoicesLoading: false,
  isAttestationsLoading: false,
  isStripeIntentLoading: false,
  isReportLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TUTEE_SET:
      return {...state, tutee: action.tutee};

    case TUTEE_SET_INVOICES:
      return {...state, invoices: action.invoices};

    case TUTEE_SET_ATTESTATIONS:
      return {...state, attestations: action.attestations};

    case TUTEE_SET_CARD_INFO:
      return {...state, card: action.card};

    case TUTEE_SET_STRIPE_INTENT:
      return {...state, stripeIntent: action.stripeIntent};

    case TUTEE_SET_LESSONS_NEXT:
      return {...state, lessonsNext: action.lessonsNext};

    case TUTEE_SET_LESSONS_PAST:
      return {...state, lessonsPast: action.lessonsPast};

    case TUTEE_SET_LOADER:
      return {...state, isLoading: action.isLoading};

    case TUTEE_SET_SAVE_LOADER:
      return {...state, isSaving: action.isSaving};

    case TUTEE_SET_PAYMENT_SAVE_LOADER:
      return {...state, isPaymentSaving: action.isSaving};

    case TUTEE_SET_INVOICES_LOADER:
      return {...state, isInvoicesLoading: action.isLoading};

    case TUTEE_SET_ATTESTATIONS_LOADER:
      return {...state, isAttestationsLoading: action.isLoading};

    case TUTEE_SET_CARD_INFO_LOADER:
      return {...state, isCardInfoLoading: action.isLoading};

    case TUTEE_SET_STRIPE_INTENT_LOADER:
      return {...state, isStripeIntentLoading: action.isLoading};

    case TUTEE_SET_REPORT_LOADER:
      return {...state, isReportLoading: action.isLoading};

    default:
      return state;
  }
};

export const getTutee = (userUuid) => dispatch => {
  dispatch({ type: TUTEE_SET, tutee: {} });
  dispatch({ type: TUTEE_SET_LOADER, isLoading: true });

  return getTuteeRequest(userUuid)
    .then(({tutee = {}}) => {
      dispatch({ type: TUTEE_SET_LOADER, isLoading: false });
      return dispatch({ type: TUTEE_SET, tutee });
    })
    .catch(error => dispatch({ type: TUTEE_SET_LOADER, isLoading: false }));
}

export const getTuteeInvoices = () => dispatch => {
  dispatch({ type: TUTEE_SET_INVOICES, invoices: [] });
  dispatch({ type: TUTEE_SET_INVOICES_LOADER, isLoading: true });

  return getTuteeInvoicesRequest()
    .then(({invoices = []}) => {
      dispatch({ type: TUTEE_SET_INVOICES_LOADER, isLoading: false });
      return dispatch({ type: TUTEE_SET_INVOICES, invoices });
    })
    .catch(error => dispatch({ type: TUTEE_SET_INVOICES_LOADER, isLoading: false }));
}

export const getTuteeAttestations = () => dispatch => {
  dispatch({ type: TUTEE_SET_ATTESTATIONS, attestations: [] });
  dispatch({ type: TUTEE_SET_ATTESTATIONS_LOADER, isLoading: true });

  return getTuteeAttestationsRequest()
    .then(({attestations = []}) => {
      dispatch({ type: TUTEE_SET_ATTESTATIONS_LOADER, isLoading: false });
      return dispatch({ type: TUTEE_SET_ATTESTATIONS, attestations });
    })
    .catch(error => dispatch({ type: TUTEE_SET_ATTESTATIONS_LOADER, isLoading: false }));
}

export const getTuteeCardInfo = () => dispatch => {
  dispatch({ type: TUTEE_SET_CARD_INFO, card: {} });
  dispatch({ type: TUTEE_SET_CARD_INFO_LOADER, isLoading: true });

  return getTuteeCardInfoRequest()
    .then(({card = {}}) => {
      dispatch({ type: TUTEE_SET_CARD_INFO_LOADER, isLoading: false });
      return dispatch({ type: TUTEE_SET_CARD_INFO, card });
    })
    .catch(error => dispatch({ type: TUTEE_SET_CARD_INFO_LOADER, isLoading: false }));
}


export const saveTuteePayment = (payment) => dispatch => {
  dispatch({ type: TUTEE_SET_PAYMENT_SAVE_LOADER, isSaving: true });

  return setTuteePaymentRequest(payment)
    .then(response => {
      dispatch({ type: TUTEE_SET_PAYMENT_SAVE_LOADER, isSaving: false });
      dispatch(getTuteeCardInfo());
      return response;
    })
    .catch(error => {
      dispatch({ type: TUTEE_SET_PAYMENT_SAVE_LOADER, isSaving: false });

      return Promise.reject(error);
    });
}

export const saveTutee = (userUuid, data) => dispatch => {
  dispatch({ type: TUTEE_SET_SAVE_LOADER, isSaving: true });

  return setTuteeRequest(userUuid, data)
    .then(() => {
      dispatch({ type: TUTEE_SET_SAVE_LOADER, isSaving: false });

      return dispatch(getTutee(userUuid));
    })
    .catch(error => {
      dispatch({ type: TUTEE_SET_SAVE_LOADER, isSaving: false });
    });
}

export const createTuteeStripeIntent = () => dispatch => {
  dispatch({ type: TUTEE_SET_STRIPE_INTENT, stripeIntent: [] });
  dispatch({ type: TUTEE_SET_STRIPE_INTENT_LOADER, isLoading: true });

  return createTuteeStripeIntentRequest()
    .then(({setup_intent}) => {
      dispatch({ type: TUTEE_SET_STRIPE_INTENT_LOADER, isLoading: false });
      return dispatch({ type: TUTEE_SET_STRIPE_INTENT, stripeIntent: setup_intent || {} });
    })
    .catch(error => dispatch({ type: TUTEE_SET_STRIPE_INTENT_LOADER, isLoading: false }));
}

export const getTuteeReport = () => dispatch => {
  dispatch({ type: TUTEE_SET_LESSONS_NEXT, lessonsNext: [] });
  dispatch({ type: TUTEE_SET_LESSONS_PAST, lessonsPast: [] });
  dispatch({ type: TUTEE_SET_REPORT_LOADER, isLoading: true });

  return getTuteeReportRequest()
    .then(({report}) => {
      dispatch({ type: TUTEE_SET_REPORT_LOADER, isLoading: false });
      dispatch({ type: TUTEE_SET_LESSONS_NEXT, lessonsNext: (report || {}).lessons_next || [] });
      return dispatch({ type: TUTEE_SET_LESSONS_PAST, lessonsPast: (report || {}).lessons_past || [] });
    })
    .catch(error => dispatch({ type: TUTEE_SET_REPORT_LOADER, isLoading: false }));
}

// export const attachTuteeStripeCard = (paymentMethod, tutee) => dispatch => {
//   dispatch({ type: TUTEE_SET_STRIPE_INTENT, stripeIntent: [] });
//   dispatch({ type: TUTEE_SET_STRIPE_INTENT_LOADER, isLoading: true });

//   return attachTuteeStripeCardRequest(paymentMethod, tutee)
//     .then(({setup_intent}) => {
//       dispatch({ type: TUTEE_SET_STRIPE_INTENT_LOADER, isLoading: false });
//       return dispatch({ type: TUTEE_SET_STRIPE_INTENT, stripeIntent: setup_intent || {} });
//     })
//     .catch(error => dispatch({ type: TUTEE_SET_STRIPE_INTENT_LOADER, isLoading: false }));
// }