import * as apiService from '../services/api.service';

export const SET_USER_BANK_PRICES = 'userBank/SET_TOPICS_PRICES';
export const SET_USER_BANK_LOADER = 'userBank/SET_LOADER';
export const SET_USER_BANK_SAVING_LOADER = 'userBank/SET_SAVING_LOADER';

const initialState = {
  bank: {},
  isLoading: false,
  isSaving: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_BANK_PRICES:
      return {...state, bank: {...action.bank}};

    case SET_USER_BANK_LOADER:
      return {...state, isLoading: action.isLoading};

    case SET_USER_BANK_SAVING_LOADER:
      return {...state, isSaving: action.isSaving};

    default:
      return state;
  }
};


export const getUserBank = userUuid => dispatch => {
  dispatch({ type: SET_USER_BANK_PRICES, bank: {} });
  dispatch({ type: SET_USER_BANK_LOADER, isLoading: true });

  return apiService.getUserBank(userUuid)
    .then((response) => {
      const bank = response && response.bank ? response.bank : {};

      dispatch({ type: SET_USER_BANK_LOADER, isLoading: false });
      return dispatch({ type: SET_USER_BANK_PRICES, bank });
    })
    .catch(error => {
      dispatch({ type: SET_USER_BANK_LOADER, isLoading: false });
      // if (typeof alert === 'function') alert(error);
    });
}

export const saveUserBank = (userUuid, bank) => dispatch => {
  dispatch({ type: SET_USER_BANK_SAVING_LOADER, isSaving: true });

  return apiService.setUserBank(userUuid, bank)
    .then(() => {
      dispatch({ type: SET_USER_BANK_SAVING_LOADER, isSaving: false });

      return dispatch(getUserBank(userUuid));
    })
    .catch(error => {
      dispatch({ type: SET_USER_BANK_SAVING_LOADER, isSaving: false });
      // if (typeof alert === 'function') alert(error);
    });
}