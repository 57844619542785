import React from 'react';
import { connect } from 'react-redux';
import { push } from "react-router-redux";
import Box from "../box";

const RouterLink = ({href, route, goTo, onBeforeClick, ...otherProps}) => (
  <Box
    component="a"
    href={route || href}
    onClick={ev => {
      const _route = route || href;

      if (onBeforeClick) {
        onBeforeClick();
      }

      if (!ev.ctrlKey && !ev.metaKey && _route) {
        ev.preventDefault();
        goTo(_route);
      }
    }}
    {...otherProps}
  />
);

RouterLink.defaultProps = {
  href: '',
  route: null
};

export default connect(
  null,
  dispatch => ({
    goTo: page => {
      dispatch(push(page));
    }
  })
)(RouterLink);