import React from 'react';
import RouterLink from '../../../../../router-link';
import { ROUTES } from '../../../../../../routes';

export default ({currentUser, redirectToAuthApp}) => {
  const href = '/tutee/messagerie?conversation';
  const isValidatedTutor = currentUser.user_tutor === 1 && currentUser.is_fully_validated;
  const isNotTutor = currentUser.user_tutor !== 1;

  if (isValidatedTutor || isNotTutor) {
    const badge = currentUser.new_message_count > 0
      ? (
        <span className="badge">
          {currentUser.new_message_count}
        </span>
      )
      : null;

    return (
      <li>
        <RouterLink
          {...(isValidatedTutor
            ? { route: ROUTES.TUTOR_MAIL_BOX }
            : (isNotTutor
              ? {
                href,
                onClick: ev => {
                  ev.preventDefault();
                  redirectToAuthApp({ redirect: href });
                }
              }
              : {}
            )
          )}
        >
          <span className="visible-xs">
            <span>
              <i className="fa fa-envelope-o" /> Messagerie
            </span>

            {badge}
          </span>

          <span className="hidden-xs">
            <i className="fa fa-envelope-o" />

            {badge}
          </span>
        </RouterLink>
      </li>
    )
  }

  return null;
}