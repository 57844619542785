import React from 'react';
import { ROUTES } from '../../../../../../routes';

export default ({goTo}) => (
  <li className="active-link">
    <a
      href={ROUTES.SCHOOLS}
      onClick={event => {
        event.preventDefault();
        goTo(ROUTES.SCHOOLS);
      }}
    >
      Les écoles
    </a>
  </li>
)