// import React from 'react';
import Loadable from 'react-loadable';


/**
 * TUTOR
 */
export const ADMIN_TUTOR_REPORTS_ROUTES = {
  TUTOR_REPORTS_COURSE: '/tutor/reports/course',
  TUTOR_REPORTS_PAYMENTS: '/tutor/reports/payments',
  TUTOR_REPORTS_LESSONS: '/tutor/reports/lessons',
  TUTOR_REPORTS_FAMILIES: '/tutor/reports/families',
};
export const ADMIN_TUTOR_ACCOUNT_ROUTES = {
  TUTOR_ACCOUNT_INFOCARD: '/tutor/account/infocard',
  TUTOR_ACCOUNT_CURSUS: '/tutor/account/cursus',
  TUTOR_ACCOUNT_TOPICS: '/tutor/account/topics',
  TUTOR_ACCOUNT_PREMIUM_LIST: '/tutor/account/liste-prioritaire',
  TUTOR_ACCOUNT_SETTINGS: '/tutor/account/settings',
  TUTOR_ACCOUNT_BANK: '/tutor/account/bank',
};
export const ADMIN_TUTOR_ROUTES = {
  TUTOR_ONBOARDING: '/tutor/onboarding',
  TUTOR_DASHBOARD: '/tutor/dashboard',
  TUTOR_MAIL_BOX: '/tutor/messagerie',
  TUTOR_SESSION: '/tutor/session',
  TUTOR_COURSE_OFFERS: '/tutor/offres',
  ...ADMIN_TUTOR_REPORTS_ROUTES,
  ...ADMIN_TUTOR_ACCOUNT_ROUTES,
};


/**
 * TUTEE
 */
export const ADMIN_TUTEE_ACCOUNT_ROUTES = {
  TUTEE_ACCOUNT_PERSONAL_INFO: '/tutee/profile/mon-compte',
  TUTEE_ACCOUNT_CHILDREN: '/tutee/profile/enfants',
  TUTEE_ACCOUNT_BANK_DETAILS: '/tutee/profile/coordonnee-bancaire',
  TUTEE_SAI: '/tutee/sai',
};
export const ADMIN_TUTEE_COURSE_MONITORING_ROUTES = {
  TUTEE_COURSE_MONITORING_PLANNING: '/tutee/account/planning',
  TUTEE_COURSE_MONITORING_EDUCATIONAL_SUPPORT: '/tutee/account/educational-support',
};

export const ADMIN_TUTEE_ROUTES = {
  TUTEE_ACCOUNT_INVOICES: '/tutee/profile/factures',
  TUTEE_ACCOUNT_AF: '/tutee/profile/af',
  TUTEE_PAUSE: '/tutee/pause',
  TUTEE_REFERRAL: '/tutee/referral',
  TUTEE_SOUTENIR: '/tutee/soutenir',
  TUTEE_AIDE: '/tutee/aide',
  TUTEE_HOW_IT_WORKS: '/tutee/how-it-works',
  TUTEE_START: '/tutee/start',
  TUTEE_PROPOSAL: '/tutee/proposal',
  TUTEE_SAI: '/tutee/sai',
  ...ADMIN_TUTEE_ACCOUNT_ROUTES,
  ...ADMIN_TUTEE_COURSE_MONITORING_ROUTES,
};


export const ADMIN_ROUTES = {
  ...ADMIN_TUTOR_ROUTES,
  ...ADMIN_TUTEE_ROUTES,
  // TODO /validation
};

export const isAdminPage = path => path && Object.keys(ADMIN_ROUTES)
  .map(routeName => ADMIN_ROUTES[routeName])
  .some(routePath => routePath === path);


/**
 * TUTOR
 */
const TutorDashboard = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-dashboard" */ './tutor/dashboard'),
  loading: () => null
});
const TutorOnboarding = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-onboarding" */ './tutor/onboarding'),
  loading: () => null
});
const TutorMailBox = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-mail-box" */ './tutor/mailBox'),
  loading: () => null
});
const TutorSession = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-session" */ './tutor/session'),
  loading: () => null
});
const TutorCourseOffers = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-course-offers" */ './tutor/course-offers'),
  loading: () => null
});
// reports
const TutorReportsCourse = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-reports-course" */ './tutor/reports/course'),
  loading: () => null
});
const TutorReportsFamilies = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-reports-families" */ './tutor/reports/families'),
  loading: () => null
});
const TutorReportsLessons = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-reports-lessons" */ './tutor/reports/lessons'),
  loading: () => null
});
// account
const TutorAccountInfocard = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-account-infocard" */ './tutor/account/infocard'),
  loading: () => null
});
const TutorAccountTopics = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-account-topics" */ './tutor/account/topics'),
  loading: () => null
});
const TutorAccountCursus = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-account-cursus" */ './tutor/account/cursus'),
  loading: () => null
});
const TutorAccountListePrioritaire = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-account-liste-prioritaire" */ './tutor/account/listePrioritaire'),
  loading: () => null
});
const TutorAccountBank = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-account-bank" */ './tutor/account/bank'),
  loading: () => null
});
const TutorAccountSettings = Loadable({
  loader: () => import(/* webpackChunkName: "tutor-account-settings" */ './tutor/account/settings'),
  loading: () => null
});


/**
 * TUTEE
 */
const TuteePause = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-pause" */ './tutee/pause'),
  loading: () => null
});
const TuteeReferral = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-pause" */ './tutee/referral'),
  loading: () => null
});
const TuteeSoutenir = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-pause" */ './tutee/soutenir'),
  loading: () => null
});
const TuteeAide = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-pause" */ './tutee/aide'),
  loading: () => null
});
const TuteeHowItWorks = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-how-it-works" */ './tutee/howItWorks'),
  loading: () => null
});
const TuteeStart = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-start" */ './tutee/start'),
  loading: () => null
});
const TuteeProposal = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-proposal" */ './tutee/proposal'),
  loading: () => null
});
// account
const TuteeAccountPersonalInfo = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-account-personal-info" */ './tutee/account/personalInfo'),
  loading: () => null
});
const TuteeSai = Loadable({
  loader: () => import('./tutee/account/avance-immediate'),
  loading: () => null
});
const TuteeAccountBankDetails = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-account-bank-details" */ './tutee/account/bank-details'),
  loading: () => null
});
const TuteeAccountChildren = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-account-children" */ './tutee/account/children'),
  loading: () => null
});
const TuteeAccountInvoices = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-account-invoices" */ './tutee/account/invoices'),
  loading: () => null
});
const TuteeAccountAttestations = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-account-invoices" */ './tutee/account/attestations'),
  loading: () => null
});
// course-monitoring
const TuteeCourseMonitoringPlanning = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-course-monitoring-planning" */ './tutee/course-monitoring/planning'),
  loading: () => null
});
const TuteeCourseMonitoringEducationalSupport = Loadable({
  loader: () => import(/* webpackChunkName: "tutee-course-monitoring-educational-support" */ './tutee/course-monitoring/educational-support'),
  loading: () => null
});


export const AdminRoutes = [
  /**
   * TUTOR
   */
  {path: ADMIN_ROUTES.TUTOR_ONBOARDING, component: TutorOnboarding},
  {path: ADMIN_ROUTES.TUTOR_DASHBOARD, component: TutorDashboard},
  {path: ADMIN_ROUTES.TUTOR_MAIL_BOX, component: TutorMailBox},
  {path: ADMIN_ROUTES.TUTOR_SESSION, component: TutorSession},
  {path: ADMIN_ROUTES.TUTOR_COURSE_OFFERS, component: TutorCourseOffers},
  // reports
  {path: ADMIN_ROUTES.TUTOR_REPORTS_COURSE, component: TutorReportsCourse},
  {path: ADMIN_ROUTES.TUTOR_REPORTS_PAYMENTS, component: TutorReportsLessons}, // TODO
  {path: ADMIN_ROUTES.TUTOR_REPORTS_LESSONS, component: TutorReportsLessons},
  {path: ADMIN_ROUTES.TUTOR_REPORTS_FAMILIES, component: TutorReportsFamilies},
  // account
  {path: ADMIN_ROUTES.TUTOR_ACCOUNT_INFOCARD, component: TutorAccountInfocard},
  {path: ADMIN_ROUTES.TUTOR_ACCOUNT_CURSUS, component: TutorAccountCursus},
  {path: ADMIN_ROUTES.TUTOR_ACCOUNT_TOPICS, component: TutorAccountTopics},
  {path: ADMIN_ROUTES.TUTOR_ACCOUNT_PREMIUM_LIST, component: TutorAccountListePrioritaire},
  {path: ADMIN_ROUTES.TUTOR_ACCOUNT_SETTINGS, component: TutorAccountSettings},
  {path: ADMIN_ROUTES.TUTOR_ACCOUNT_BANK, component: TutorAccountBank},


  /**
   * TUTEE
   */
  {path: ADMIN_ROUTES.TUTEE_PAUSE, component: TuteePause},
  {path: ADMIN_ROUTES.TUTEE_REFERRAL, component: TuteeReferral},
  {path: ADMIN_ROUTES.TUTEE_SOUTENIR, component: TuteeSoutenir},
  {path: ADMIN_ROUTES.TUTEE_AIDE, component: TuteeAide},
  {path: ADMIN_ROUTES.TUTEE_HOW_IT_WORKS, component: TuteeHowItWorks},
  {path: ADMIN_ROUTES.TUTEE_START, component: TuteeStart},
  {path: ADMIN_ROUTES.TUTEE_PROPOSAL, component: TuteeProposal},
  {path: ADMIN_ROUTES.TUTEE_SAI, component: TuteeSai},
  // account
  {path: ADMIN_ROUTES.TUTEE_ACCOUNT_PERSONAL_INFO, component: TuteeAccountPersonalInfo},
  {path: ADMIN_ROUTES.TUTEE_ACCOUNT_CHILDREN, component: TuteeAccountChildren},
  {path: ADMIN_ROUTES.TUTEE_ACCOUNT_INVOICES, component: TuteeAccountInvoices},
  {path: ADMIN_ROUTES.TUTEE_ACCOUNT_AF, component: TuteeAccountAttestations},
  {path: ADMIN_ROUTES.TUTEE_ACCOUNT_BANK_DETAILS, component: TuteeAccountBankDetails},
  // course-monitoring
  {path: ADMIN_ROUTES.TUTEE_COURSE_MONITORING_PLANNING, component: TuteeCourseMonitoringPlanning},
  {path: ADMIN_ROUTES.TUTEE_COURSE_MONITORING_EDUCATIONAL_SUPPORT, component: TuteeCourseMonitoringEducationalSupport},
];
