import * as API from '../services/api.service';

export const COURS_SET_CITY = 'cours/SET_CITY';
export const COURS_SET_TOPIC = 'cours/SET_TOPIC';
export const COURS_SET_TOPIC_CITY = 'cours/SET_TOPIC_CITY';
export const COURS_SET_LEVEL = 'cours/SET_LEVEL';
export const COURS_SET_CITIES = 'cours/SET_CITIES';
export const COURS_SET_TOPICS = 'cours/SET_TOPICS';
export const COURS_SET_CITY_LOADER = 'cours/SET_CITY_LOADER';
export const COURS_SET_TOPIC_LOADER = 'cours/SET_TOPIC_LOADER';
export const COURS_SET_TOPIC_CITY_LOADER = 'cours/SET_TOPIC_CITY_LOADER';
export const COURS_SET_LEVEL_LOADER = 'cours/SET_LEVEL_LOADER';

const initialState = {
  city: {},
  topic: {},
  topicCity: {},
  level: {},
  cities: [],
  topics: [],
  isCoursCityLoading: false,
  isCoursTopicLoading: false,
  isCoursTopicCityLoading: false,
  isCoursLevelLoading: false,
  isCitiesLoaded: false,
  isTopicsLoaded: false,
  isCoursCityLoaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COURS_SET_CITY:
      return {
        ...state,
        city: action.city,
        isCoursCityLoaded: true
      };

    case COURS_SET_TOPIC:
      return {
        ...state,
        topic: action.topic
      };

    case COURS_SET_TOPIC_CITY:
      return {
        ...state,
        topicCity: action.topicCity
      };

    case COURS_SET_LEVEL:
      return {
        ...state,
        level: action.level
      };

    case COURS_SET_CITIES:
      return {
        ...state,
        cities: action.cities,
        isCitiesLoaded: true
      };

    case COURS_SET_TOPICS:
      return {
        ...state,
        topics: action.topics,
        isTopicsLoaded: true
      };

    case COURS_SET_CITY_LOADER:
      return {
        ...state,
        isCoursCityLoading: action.value
      };

    case COURS_SET_TOPIC_LOADER:
      return {
        ...state,
        isCoursTopicLoading: action.value
      };

    case COURS_SET_TOPIC_CITY_LOADER:
      return {
        ...state,
        isCoursTopicCityLoading: action.value
      };

    case COURS_SET_LEVEL_LOADER:
      return {
        ...state,
        isCoursLevelLoading: action.value
      };

    default:
      return state;
  }
};

export const getCoursCity = cityName => dispatch =>
  new Promise(resolve => {
    dispatch(setCityLoader(true));

    API.getCoursCity(cityName)
      .then(city => {
        dispatch(setCity(city));
        dispatch(setCityLoader(false));
        resolve();
      })
      .catch(() => {
        dispatch(setCityLoader(false));
        resolve();
      });
  });

export const getCoursTopic = topicName => dispatch =>
  new Promise(resolve => {
    dispatch(setTopicLoader(true));

    API.getCoursTopic(topicName)
      .then(topic => {
        dispatch(setTopic(topic));
        dispatch(setTopicLoader(false));
        resolve();
      })
      .catch(() => {
        dispatch(setTopicLoader(false));
        resolve();
      });
  });

/**
 * @param {String} topicName 
 * @param {String} cityName 
 */
export const getCoursTopicCity = (topicName, cityName) => dispatch =>
  new Promise(resolve => {
    dispatch(setTopicCityLoader(true));

    API.getCoursTopicCity(topicName, cityName)
      .then(topic => {
        dispatch(setTopicCity(topic));
        dispatch(setTopicCityLoader(false));
        resolve();
      })
      .catch(() => {
        dispatch(setTopicCityLoader(false));
        resolve();
      });
  });

export const getCoursLevel = (levelName) => dispatch =>
  new Promise(resolve => {
    dispatch(setLevelLoader(true));

    API.getCoursLevel(levelName)
      .then(level => {
        dispatch(setLevel(level));
        dispatch(setLevelLoader(false));
        resolve();
      })
      .catch(() => {
        dispatch(setLevelLoader(false));
        resolve();
      });
  });

export const getCoursCities = () => dispatch =>
  new Promise(resolve => {
    // dispatch(setCityLoader(true));

    API.getCoursCities()
      .then(({ links }) => {
        dispatch(setCities(links));
        // dispatch(setCityLoader(false));
        resolve();
      })
      .catch(() => {
        // dispatch(setCityLoader(false));
        resolve();
      });
  });

export const getCoursTopics = () => dispatch =>
  new Promise(resolve => {
    // dispatch(setTopicLoader(true));

    API.getCoursTopics()
      .then(({ links }) => {
        dispatch(setTopics(links));
        // dispatch(setTopicLoader(false));
        resolve();
      })
      .catch(() => {
        // dispatch(setTopicLoader(false));
        resolve();
      });
  });

const setCityLoader = value => ({
  type: COURS_SET_CITY_LOADER,
  value
});

const setTopicLoader = value => ({
  type: COURS_SET_TOPIC_LOADER,
  value
});

const setTopicCityLoader = value => ({
  type: COURS_SET_TOPIC_CITY_LOADER,
  value
});

const setLevelLoader = value => ({
  type: COURS_SET_LEVEL_LOADER,
  value
});

const setCity = city => ({
  type: COURS_SET_CITY,
  city
});

const setTopic = topic => ({
  type: COURS_SET_TOPIC,
  topic
});

const setTopicCity = topicCity => ({
  type: COURS_SET_TOPIC_CITY,
  topicCity
});

const setLevel = level => ({
  type: COURS_SET_LEVEL,
  level
});

const setCities = cities => ({
  type: COURS_SET_CITIES,
  cities
});

const setTopics = topics => ({
  type: COURS_SET_TOPICS,
  topics
});
