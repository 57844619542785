import axios from 'axios';

export const clearRedisCache = (user_id) => {
  axios.post('/clear-redis-cache',{
    user_id
  })
  .then(res=>{
  })
  .catch(error=>{
    console.log({error})
  })
}