import React, { Component } from 'react';
import Page from '../../components/page';
import './not-found.scss';
import Helmet from 'react-helmet';


class NotFound extends Component {
  constructor(props) {
    super(props);

    if (typeof document !== 'undefined') {
      document.body.classList.add('page404');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('page404');
  }

  render() {
    // const { history } = this.props;
    const title = 'Not Found';
    return (
      <Page
        id="not-found"
        title={title}
        description="This is embarrassing."
        noCrawl
      >
        <Helmet>
        <title>{title}</title>
        <meta name="robots" content="noindex" />
        <meta httpEquiv="status" content={process.env.NEXT_PUBLIC_HTTP_STATUS_NOT_FOUND+""} />
      </Helmet>
        <div className="container text-center">
          <h1>{process.env.NEXT_PUBLIC_HTTP_STATUS_NOT_FOUND}</h1>

          <h2>La page que vous essayez d'atteindre n'a pas été trouvée.</h2>
        </div>
        {/* {history && history.push('/')} */}
      </Page>
    );
  }
}

export default NotFound;
