import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Route, Redirect } from 'react-router-dom';
import { LocalStorage } from '../../services/localstorage.service';
import { ROUTES } from '../../routes';


const UnauthenticatedRoute = ({ component: Component, extraProps = {}, ...rest }) => {
  // let query = queryString.parse(rest.location.search);
  const show_ai =
  rest.user.statut_ai == 'A activer' || rest.user.statut_ai == null;
          const see_ai = LocalStorage.getItem('see_ai_page') || 'on';
          const see_ai_ = see_ai === 'on' && show_ai && rest.user.eligible_sai && rest.user.wants_visio=='f';
          var tutee_route='/tutee/profile/mon-compte'
          tutee_route = rest.user.customer_type === 'client'
              ? see_ai_ ? ROUTES.TUTEE_SAI : ROUTES.TUTEE_COURSE_MONITORING_PLANNING
              : ROUTES.TUTEE_START
  // console.log("unauthenticaed -route ....",{tutee_route})
  return (
    <Route
      {...rest}
      render={props =>
        !rest.isAuthenticated ? (
          <Component {...extraProps} {...props} />
        ) : (
          <Redirect to={Number(rest.user.user.is_tutor) ? '/tutor/dashboard' : tutee_route} />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.currentUser
});

export default connect(
  mapStateToProps,
  null
)(UnauthenticatedRoute);
