import * as API from '../services/api.service';
import { QUOTES, VIDEOS } from '../mocks/reviews.mock';

export const SET_REVIEWS_QUOTES = 'reviews/SET_QUOTES';
export const SET_REVIEWS_VIDEOS = 'reviews/SET_VIDEOS';
export const SET_REVIEWS_QUOTES_LOADER = 'reviews/SET_QUOTES_LOADER';

const initialState = {
  quotes: [],
  videos: [],
  isQuotesLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_REVIEWS_QUOTES:
      return {
        ...state,
        quotes: [...action.quotes]
      };

    case SET_REVIEWS_VIDEOS:
      return {
        ...state,
        videos: [...action.videos]
      };

    case SET_REVIEWS_QUOTES_LOADER:
      return {
        ...state,
        isQuotesLoading: action.isLoading
      };

    default:
      return state;
  }
};

export const getReviewsQuotes = () => dispatch => {
  dispatch({ type: SET_REVIEWS_QUOTES_LOADER, isLoading: true });
  dispatch({ type: SET_REVIEWS_QUOTES, quotes: [] });

  return API.getReviews()
    .then(({testimonies}) => {
      const adaptedQuotes = (testimonies || []).map(item => ({
        id: item.text_id,
        text: item.testimony,
        cite: item.nom,
        cite_2: `${item.matiere} ${item.niveau}`,
        avatar: item.image_uri
      }))

      dispatch({ type: SET_REVIEWS_QUOTES, quotes: adaptedQuotes })

      return dispatch({ type: SET_REVIEWS_QUOTES_LOADER, isLoading: false });
    })
    .catch(err => dispatch({ type: SET_REVIEWS_QUOTES_LOADER, isLoading: false }));
}

export const getReviewsVideos = () => dispatch => {
  const videos = VIDEOS;

  dispatch({ type: SET_REVIEWS_VIDEOS, videos });

  return Promise.resolve(videos);
}
