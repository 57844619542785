import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const AuthenticatedRoute = ({ component: Component, extraProps = {}, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isUserLoading || rest.isAuthenticated === null // User loading
        ? null
        : (
          rest.isAuthenticated === true
          ? (<Component {...extraProps} {...props} />)
          : (<Redirect to={`/sign-up?redirect=${props.location.pathname}`} />)
        )
    }
  />
);

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isUserLoading: state.auth.isUserLoading,
});

export default connect(
  mapStateToProps,
  null
)(AuthenticatedRoute);
