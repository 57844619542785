import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Box from '../../../box';
import { HEADER_HEIGHT, PHONE_NUMBER } from '../../';
import { compareTwoStrings } from 'string-similarity';
import { BLACK_LISTED_PAGES, PATH_MATCH_THRESHOLD } from './header-additional.constants';
import CustomButton from '../../../../routes/forgot-password/CustomButton';
import { Button } from '@material-ui/core';
import { Phone as PhoneIcon } from '@material-ui/icons';
// import SponsorsBox from '../SponsorsBox';

export const HEADER_ADDITIONAL_HEIGHT = 62;

const HeaderAdditionalStyled = styled(({ fixed, ...rest }) => <div {...rest} />)(({ fixed }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  minHeight: HEADER_ADDITIONAL_HEIGHT,
  width: '100%',
  color: '#ffffff !important',
  backgroundColor: '#145A8F',
  fontSize: 20,
  zIndex: 3,
  ...(fixed ? { position: 'fixed', marginTop: HEADER_HEIGHT, } : {})
}));

const PhoneNumberStyled = styled(Box)({
  display: 'inline-block',
  fontWeight: 600
});

/**@param {String} path */
const getSlashesCount = (path) => path.split('').reduce((total, x) => x === '/' ? total + 1 : total)

const HeaderAdditional = ({ fixed = true, location: { pathname } }) => {
  const [showNumber, setShowNumber] = useState(true);

  useEffect(() => {
    function onScroll(event) {
      if (window.scrollY > 64) {
        setShowNumber(false)
      } else {
        setShowNumber(true)
      }
    }

    if(window.location.pathname =='/tutor/offres' || (window.location.pathname.indexOf('tutee')>=0 &&  window.location.pathname !=='/tutee/start')){
      setShowNumber(false)
    }else{
      window.addEventListener('scroll', onScroll)
    }
    return () => window.removeEventListener('scroll', onScroll)
  });

  const isHidden = BLACK_LISTED_PAGES
    .some(path =>
      compareTwoStrings(path, pathname) >= PATH_MATCH_THRESHOLD
      && getSlashesCount(path) === getSlashesCount(pathname)
    );

  return (
    (isHidden || !showNumber)
      ? null
      : <HeaderAdditionalStyled fixed={fixed}>
        {/* <Box>
          Nous sommes là, appelez-nous :&nbsp;<PhoneNumberStyled>{PHONE_NUMBER}</PhoneNumberStyled>
        </Box> */}
        <Button
            href={`tel:${PHONE_NUMBER}`}
            style={{
              border: '1px solid white',
              borderRadius: 40,
              fontWeight: 600,
              padding: '7px 16px 5px 16px',
              color: 'white',
              fontSize: 12,
              cursor: 'pointer',
            }}
          >
            <PhoneIcon style={{ marginRight: 8, fontSize: 16, marginBottom: 3 }} />
            {PHONE_NUMBER}
        </Button>
      </HeaderAdditionalStyled>
  );
}

export default HeaderAdditional;