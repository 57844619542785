import { getGenericPageRequest } from '../services/api.service';

export const GENERIC_PAGE_SET_PAGE = 'genericPage/SET_PAGE';
export const GENERIC_PAGE_SET_LOADER = 'genericPage/SET_LOADER';

const initialState = {
  page: {},
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GENERIC_PAGE_SET_PAGE:
      return { ...state, page: action.page };

    case GENERIC_PAGE_SET_LOADER:
      return { ...state, isLoading: action.isLoading };

    default:
      return state;
  }
};

export const fetchGenericPage = page => dispatch => {
  dispatch(setGenericPageLoader(true));
  dispatch({type: GENERIC_PAGE_SET_PAGE, page: {}});

  return getGenericPageRequest(page)
    .then(response => {
      dispatch({type: GENERIC_PAGE_SET_PAGE, page: response || {}});

      return dispatch(setGenericPageLoader(false));
    })
    .catch(err => dispatch(setGenericPageLoader(false)));
}

export const setGenericPageLoader = isLoading => ({
  type: GENERIC_PAGE_SET_LOADER,
  isLoading
});
