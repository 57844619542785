import axios from './client';
import { LocalStorage } from './localstorage.service';
import { justifyName } from '../utils';
import { isServer } from '../store';
import { isNode } from "browser-or-node";



const BASE_URL = isNode
  ? process.env.NEXT_PUBLIC_LOCAL_BASE_URL
  : process.env.NEXT_PUBLIC_BASE_URL;
const BASE_URL_V3 = isNode
  ? process.env.NEXT_PUBLIC_LOCAL_BASE_URL_V3
  : process.env.NEXT_PUBLIC_BASE_URL_V3;
const CACHE_API_URL = isNode
  ? process.env.NEXT_PUBLIC_LOCAL_CACHE_API_URL
  : process.env.NEXT_PUBLIC_CACHE_API_URL;

const SEO_BASE_URL = CACHE_API_URL || BASE_URL_V3;
const TYPES_BASE_URL = CACHE_API_URL || BASE_URL;
const STATIC_BASE_URL = CACHE_API_URL ? `${CACHE_API_URL}/static` : `${process.env.NEXT_PUBLIC_STATIC_BASE_URL}/en`;
const methods = ['get', 'delete', 'head', 'post', 'put', 'patch'];
export const _send = {};
methods.forEach(
  method => 
    (_send[method] = (...attrs) => {
      //console.log(BASE_URL, BASE_URL_V3, CACHE_API_URL);
      //console.log(attrs);
      return axios()
        [method](...attrs)
        .then(response => response.data)
        .catch(({response: {data = {}} = {}} = {}) => Promise.reject(data));
      }
    )
);

const _sendWithHeaders = {};
methods.forEach(method => _sendWithHeaders[method] = (...attrs) => {
  const authToken = LocalStorage.getItem('auth_token');
  // const sessionUuid = LocalStorage.getItem('session_uuid');
  // const companyUuid = LocalStorage.getItem('company_uuid');
  // const projectUuid = LocalStorage.getItem('project_uuid');
  const headers = { 'X-Api-Version': 'CLEVERMATE_2019' };

  const [url] = attrs;
  const newAttrs = [url];
  let data, config = {};

  if (authToken) { headers['X-Auth-Token'] = authToken; }
  // if (authToken) { headers['X-Auth-Token'] = 'cfede11a-f51b-49f2-9576-d90824e27240'; } // TODO: TMP

  // if (sessionUuid) { headers['X-Session-Token'] = sessionUuid; }
  // if (companyUuid) { headers['X-Company-Token'] = companyUuid; }
  // if (projectUuid) { headers['X-Project-Token'] = projectUuid; }

  switch (method) {
    case 'get': case 'delete': case 'head':
      [ ,config ] = attrs;
      break;

    case 'post': case 'put': case 'patch':
      [ , data, config ] = attrs;
      newAttrs.push(data);
      break;
  }

  config = config || {};
  config = {...config, headers: {...headers, ...(config.headers || {})}}
  newAttrs.push(config);

  return _send[method](...newAttrs);
});

export const getUsersRequest = (filters = {}, options = {}) => {
  filters.teach = filters.teach || 'all';
  filters.level = filters.level || 'all';
  filters.location = filters.location || 'all';

  // TODO Remove after Users API filters implementation
  // FOR tests
  // const _types = {"BUS":false,"ING":true,"SCP":true,"OTH":true,"ENS":true};
  // filters.schoolCategories = Object.keys(_types)
  //   .filter(paramName => _types[paramName]);
  // filters.schoolSlug = 'scpo';
  // filters.selectedOptions = ['photo_filename', 'course_at_home'];

  const params = [
    ...Object.keys(filters)
      .filter(paramName => filters[paramName])
      .map(paramName => {
        const value = filters[paramName];

        return Array.isArray(value)
          ? value.map(item => `filters[${paramName}][]=${item}`).join('&')
          : `filters[${paramName}]=${value}`;
      }),

    ...Object.keys(options)
      .filter(paramName => options[paramName])
      .map(paramName => `${paramName}=${options[paramName]}`)
  ].filter(i => i);

  return _sendWithHeaders.get(`${BASE_URL}/users?${encodeURI(params.join('&'))}`);
};

export const getUser = id =>
  _sendWithHeaders.get(`${BASE_URL}/users/${id}?options[level]=all`);

// export const getUserTypesRequest = () => _sendWithHeaders.get(`/api/types`);
// export const getUserTypesRequest = () => _sendWithHeaders.get(`${BASE_URL}/types`);
export const getUserTypesRequest = () => _sendWithHeaders.get(`${TYPES_BASE_URL}/types`);

export const getStaticPage = pageSlug =>
  _sendWithHeaders.get(`${STATIC_BASE_URL}/${pageSlug}`);

export const prospectSaveProfile = (data = {}) =>
  _sendWithHeaders.post(`${BASE_URL_V3}/prospect/save-profile`, data);

export const sendProfileMessage = (sender_uuid, recipient_uuid, message) =>
  // TODO Check do we need slash at the end
  _sendWithHeaders.post(`${BASE_URL}/messages/`, {
    recipient: recipient_uuid,
    sender: sender_uuid,
    body: message
  });

export const sendProfileMessageV2 = (email, phone, message, teacher_uuid) =>
  _sendWithHeaders.post(`${BASE_URL_V3}/prospect/contactbox`, {
    email,
    message,
    phone,
    tutee_uuid: teacher_uuid
  });

export const getSessionRequest = authToken =>
    _sendWithHeaders.get(`${BASE_URL}/session`);

export const loginRequest = (data = {}) => _sendWithHeaders.post(`${BASE_URL}/users/login`, data);

export const signUpRequest = (data = {}) =>
  _send
    .post(
      `${BASE_URL}/users/sign-up-${
        data.typeStudent === 'tutor' ? 'tutor' : 'tutee'
      }`,
      data
    )
    .then(session => {
      LocalStorage.setItem('auth_token', session.auth_token || '');

      return getSessionRequest().then(session => {
        return session;
      });
    });

export const fbConnectRequest = (data = {}) =>
  _sendWithHeaders.post(`${BASE_URL}/users/connect/facebook`, { data });

export const isEmailExistRequest = (params = {}) =>
  _sendWithHeaders.get(`${BASE_URL}/users/is-email-exist`, { params });

export const getSchoolFormationRequest = formation =>
  _sendWithHeaders.post(`${BASE_URL}/users/get-school-formation`, { formation });

export const getSchools = () => _sendWithHeaders.get(`${BASE_URL}/schools`);

export const getSchool = slug => _sendWithHeaders.get(`${BASE_URL}/school/${slug}`);

export const getCurrentUsersBySchool = slug =>
  _sendWithHeaders.get(`${BASE_URL}/users?filters[school]=${slug}`);

export const recoverPassword = email =>
  _sendWithHeaders.post(`${BASE_URL}/forgot-password`, { email });

export const callAnalytics = () =>
    _sendWithHeaders.get(
        `${BASE_URL_V3}/analytics?random=${(Math.random() * 10)
            .toString()
            .slice(2)}`
    );

export const getCoursCity = cityName =>
  _send.get(`${SEO_BASE_URL}/seo/prepare-page-for-city?city=${justifyName(cityName)}&v3`);

export const getCoursTopic = topicName =>
  _send.get(`${SEO_BASE_URL}/seo/prepare-page-for-topic?topic=${justifyName(topicName)}&v3`);

export const getCoursTopicCity = (topicName, cityName) =>
  _send.get(`${SEO_BASE_URL}/seo/prepare-page-for-topic-city?topic=${justifyName(topicName)}&city=${justifyName(cityName)}`);

export const getCoursLevel = levelName =>
  _send.get(`${SEO_BASE_URL}/seo/prepare-page-for-level?level=${levelName}`);

export const getCoursCities = () =>
  _send.get(`${SEO_BASE_URL}/seo/homepage-links-cities`);

export const getCoursTopics = () =>
  _send.get(`${SEO_BASE_URL}/seo/homepage-links-topics`);

export const getPrivateCoursCity = cityName =>
  _send.get(`${SEO_BASE_URL}/seo/prepare-page-for-donner-des-cours${cityName ? `?city=${justifyName(cityName)}` : ''}`);

export const getPrivateCoursFaq = () =>
  _send.get(`${SEO_BASE_URL}/seo/faq`);

export const getReviews = () =>
  _send.get(`${SEO_BASE_URL}/seo/testimonies`);

export const getGenericPageRequest = page =>
  _send.get(`${SEO_BASE_URL}/seo/prepare-page-for-generic${page ? `?page=${page}` : ''}`);


/**
 * ADMIN
 */
export const getUsersCompletionRequest = userUuid =>
    _sendWithHeaders.get(`${BASE_URL}/users/${userUuid}/check/completion`)
        .then(data => ({...data, isProfileComplete: data.percentage === 100}));

export const getLessonStatusRequest = userUuid =>
    _sendWithHeaders.get(`${BASE_URL}/users/${userUuid}/state`);

export const setLessonStatusRequest = (userUuid, status) =>
    _sendWithHeaders.put(`${BASE_URL}/users/${userUuid}/state`, { state: status });

export const getInfocard = userUuid =>
    _sendWithHeaders.get(`${BASE_URL}/users/${userUuid}/description`);

export const setInfocard = (userUuid, description) =>
    _sendWithHeaders.put(`${BASE_URL}/users/${userUuid}/description`, description);

export const getTuteeRequest = userUuid =>
    _sendWithHeaders.get(`${BASE_URL}/users/${userUuid}/tutee`);

export const setTuteeRequest = (userUuid, data) =>
    _sendWithHeaders.put(`${BASE_URL}/users/${userUuid}/tutee`, data);

export const getTuteeInvoicesRequest = () =>
    _sendWithHeaders.get(`${BASE_URL_V3}/tutee/invoices`);

export const getTuteeAttestationsRequest = () =>
    _sendWithHeaders.get(`${BASE_URL_V3}/tutee/attestations`);

export const createTuteeStripeIntentRequest = (tutee) =>
    _sendWithHeaders.post(`${BASE_URL_V3}/tutee/intent`, {tutee});

export const deleteTuteeStripeIntentRequest = () =>
    _sendWithHeaders.delete(`${BASE_URL_V3}/tutee/intent`);

export const attachTuteeStripeCardRequest = (payment, personal) =>
    _sendWithHeaders.post(`${BASE_URL_V3}/tutee/attach`, {payment, personal});

export const logTuteeDataRequest = log =>
    _sendWithHeaders.post(`${BASE_URL_V3}/tutee/log`, {log});

export const getTuteeReportRequest = () =>
    _sendWithHeaders.get(`${BASE_URL_V3}/tutee/lessons/report`);

export const getTuteeCardInfoRequest = () =>
    _sendWithHeaders.get(`${BASE_URL_V3}/tutee/card-info`);

export const setTuteePaymentRequest = payment =>
    _sendWithHeaders.post(`${BASE_URL_V3}/tutee/payment`, {payment});

export const getUserMeta = userUuid =>
    _sendWithHeaders.get(`${BASE_URL}/users/${userUuid}/meta`);

export const setUserMeta = (userUuid, meta) =>
    _sendWithHeaders.put(`${BASE_URL}/users/${userUuid}/meta`, {meta});

export const getUserTopicsPrices = userUuid =>
    _sendWithHeaders.get(`${BASE_URL}/users/${userUuid}/topics-prices`);

export const setUserTopicsPrices = (userUuid, topicsPrices) =>
    _sendWithHeaders.put(`${BASE_URL}/users/${userUuid}/topics-prices`, {'topics-prices': topicsPrices});

export const getUserBank = userUuid =>
    _sendWithHeaders.get(`${BASE_URL}/users/${userUuid}/bank`);

export const setUserBank = (userUuid, bank) =>
    _sendWithHeaders.put(`${BASE_URL}/users/${userUuid}/bank`, {bank});

export const getUserCursus = userUuid =>
    _sendWithHeaders.get(`${BASE_URL}/users/${userUuid}/cursus`);

export const setUserCursus = (userUuid, cursus) =>
    _sendWithHeaders.put(`${BASE_URL}/users/${userUuid}/cursus`, {cursus});

export const getUserConnections = (userUuid) =>
    _sendWithHeaders.get(`${BASE_URL_V3}/connections/${userUuid}`);

export const getTutorTuteeLesson = (userUuid,tuteeUuid) =>
    _sendWithHeaders.get(`${BASE_URL_V3}/lesson/${userUuid}/${tuteeUuid}`);

export const getUserLessons = () =>
    _sendWithHeaders.get(`${BASE_URL_V3}/lessons`);

export const createUserLesson = (lesson = {}) =>
    _sendWithHeaders.post(`${BASE_URL_V3}/lesson`, lesson);

export const changeEmailRequest = (userUuid, email) =>
    _sendWithHeaders.put(`${BASE_URL}/users/${userUuid}/email`, { email });

export const changePhoneRequest = (userUuid, phone) =>
    _sendWithHeaders.put(`${BASE_URL}/users/${userUuid}/phone`, { phone });

export const verifyEmailRequest = (userUuid, code) =>
    // _sendWithHeaders.post(`${BASE_URL}/phone/${verificationUuid}/verify`, { code });
    _sendWithHeaders.patch(`${BASE_URL}/users/${userUuid}/email/code`, { code });

export const verifyPhoneRequest = (userUuid, code) =>
    // _sendWithHeaders.post(`${BASE_URL}/phone/${verificationUuid}/verify`, { code });
    _sendWithHeaders.patch(`${BASE_URL}/users/${userUuid}/phone/code`, { code });

export const addUserCursusDiploma  = (userUuid, data) =>
    _sendWithHeaders.post(`${BASE_URL}/users/${userUuid}/cursus/diploma`, data);

export const editUserCursusDiploma  = (id, userUuid, data) =>
    _sendWithHeaders.put(`${BASE_URL}/users/${userUuid}/cursus/diploma/${id}`, data);

export const setUserCursusMainDiploma = (id, userUuid) =>
    editUserCursusDiploma(id, userUuid, {diploma: {main: true}});

export const sendUserCursusDiplomaValidationEmail  = (userUuid, email) =>
    _sendWithHeaders.post(`${BASE_URL}/users/${userUuid}/diploma/school-email/validation/send`, {email});

export const checkUserCursusDiplomaValidationCode  = (userUuid, data) =>
    _sendWithHeaders.post(`${BASE_URL}/users/${userUuid}/diploma/school-email/validation/check`, data);

export const deleteUserAccount  = (userUuid, password) =>
    _sendWithHeaders.post(`${BASE_URL}/users/${userUuid}/manage/delete`, {password});

export const changeUserPassword  = (userUuid, old_password, new_password) =>
    _sendWithHeaders.post(`${BASE_URL}/users/${userUuid}/manage/change-password`, {old_password, new_password});

// conversations
export const getConversations = (userUuid) =>
    _sendWithHeaders.get(`${BASE_URL}/conversations?filters[user_uuid]=${userUuid}`);

export const getConversation = (conversationUuid) =>
    _sendWithHeaders.get(`${BASE_URL}/conversations/${conversationUuid}`);

export const readConversation = (conversationUuid) =>
    _sendWithHeaders.patch(`${BASE_URL}/conversations/${conversationUuid}/read`);

export const sendMessage = (message) =>
    _sendWithHeaders.post(`${BASE_URL}/messages/`, { message });

export const setNextAirtableWindowOpeningOn = (tutor_uuid,next_date) =>
  _sendWithHeaders.post(`${BASE_URL_V3}/next-airtable-window-opening-on`, { tutor_uuid,next_date });

export const sendEmailReponseCommentaire = (data={}) =>
_sendWithHeaders.post(`${BASE_URL_V3}/send-email-reponse_commentaire`, data);

export const getOffres = (userUuid) =>
    _sendWithHeaders.get(`${BASE_URL}/offres/${userUuid}`);

export const getTutorAdress = (userUuid) =>
    _sendWithHeaders.get(`${BASE_URL}/tutor_adress/${userUuid}`);

export const getOffre = (id, user_id) =>
    _sendWithHeaders.get(`${BASE_URL}/offre/${id}/${user_id}`);

export const exclureOffre = ({tutor_id, offre_id}) =>
    _sendWithHeaders.post(`${BASE_URL}/offre_exclure`, {tutor_id, offre_id});

export const candidater = ({tutor_id, offre_id, comment}) =>
    _sendWithHeaders.post(`${BASE_URL}/candidature`, {tutor_id, offre_id, comment});

export const DM_log_call = ({type="distancematrix", user=''}) =>
    _sendWithHeaders.post(`${BASE_URL}/distancematrix`, {type, user});