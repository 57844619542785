import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: '#145a8f',
    },
    secondary: {
      main: '#AF1318',
    },
  },

  typography: {
    subtitle1: {
      fontSize: 30,
      fontWeight: 600
    },
    subtitle2: {
      fontSize: 24,
      fontWeight: 400
    }
  },

  overrides: {}
});

export default theme;