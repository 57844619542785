export const SOCIAL_LINKS = [
  {
    href: "https://fr.linkedin.com/company/clevermate-fr",
    // icon: "https://image.flaticon.com/icons/svg/179/179330.svg",
    icon: "img/icons/social/new/linkedin-grey.png",
  },
  {
    href: "https://www.facebook.com/pg/Clevermate.fr/about/",
    // icon: "https://image.flaticon.com/icons/svg/145/145802.svg",
    icon: "img/icons/social/new/facebook-grey.png",
  },
  {
    href: "https://www.instagram.com/clevermatefr/",
    // icon: "https://image.flaticon.com/icons/svg/187/187207.svg",
    icon: "img/icons/social/new/instagram-grey.png",
  },
  {
    href: "https://twitter.com/ClevermateFr",
    // icon: "https://image.flaticon.com/icons/svg/185/185961.svg",
    icon: "img/icons/social/new/twitter-grey.png",
  },
  {
    href: "https://www.youtube.com/channel/UCtXjPwH8Wlzcizl8DlGZo3g",
    // icon: "https://image.flaticon.com/icons/svg/187/187210.svg",
    icon: "img/icons/social/new/youtube-grey.png",
  },
];