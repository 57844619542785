import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import session from './session.reducer';
import auth from './auth.reducer';
import profile from './profile.reducer';
import search from './search.reducer';
import seoPage from './seo-page.reducer';
import genericPage from './generic-page.reducer';
import school from './school.reducer';
import reviews from './reviews.reducer';
import staticPage from './static-page.reducer';
import cours from './cours.reducer';
import privateCours from './private-cours.reducer';
import conversations from './conversations.reducer';

// Admin reducers
import infocard from './infocard.reducer';
import userMeta from './user-meta.reducer';
import userConnections from './user-connections.reducer';
import userLessons from './user-lessons.reducer';
import userTopics from './user-topics.reducer';
import userCursus from './user-cursus.reducer';
import userBank from './user-bank.reducer';
import tutee from './tutee.reducer';

const appReducer = combineReducers({
  routerReducer,
  session,
  auth,
  profile,
  search,
  seoPage,
  genericPage,
  school,
  reviews,
  staticPage,
  cours,
  privateCours,
  conversations,
  cours,
  infocard,
  userMeta,
  userConnections,
  userLessons,
  userTopics,
  userCursus,
  userBank,
  tutee,
});

const rootReducer = (state, action) => {
  if (action.type === 'DESTROY_SESSION') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

export * from './session.reducer';
export * from './auth.reducer';
export * from './profile.reducer';
export * from './search.reducer';
export * from './seo-page.reducer';
export * from './generic-page.reducer';
export * from './school.reducer';
export * from './reviews.reducer';
export * from './static-page.reducer';
export * from './cours.reducer';
export * from './private-cours.reducer';
export * from './conversations.reducer';

// admin
export * from './infocard.reducer';
export * from './user-meta.reducer';
export * from './user-connections.reducer';
export * from './user-lessons.reducer';
export * from './user-topics.reducer';
export * from './user-cursus.reducer';
export * from './user-bank.reducer';
export * from './tutee.reducer';