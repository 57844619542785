import React from 'react';
import Page from '../../../components/page';
import '../../../routes/profile/profile.scss';
import './default.scss';
import './custom.scss';

export default ({children, style = {}, ...otherProps}) => (
  <Page
    noCrawl
    {...otherProps}
    style={style}
  >
    <div className="container">
      {children}
    </div>
  </Page>
);
