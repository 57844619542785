import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { setStaticHTML } from '../../reducers';

function StaticRouterLink({ route, goTo, setStaticHTML, ...rest }) {
  function handleLinkClick(ev) {
    ev.preventDefault();
    setStaticHTML('');
    goTo(route);
  }

  return (
    <a
      // href={`https://www.clevermate.fr${route}`}
      href={route}
      onClick={handleLinkClick}
      {...rest}
    />
  )
}

const mapDispatchToProps = dispatch => ({
  goTo: page => {
    dispatch(push(page));
  },
  setStaticHTML: html => {
    dispatch(setStaticHTML(html));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(StaticRouterLink);