import { LocalStorage } from './localstorage.service';
import { ROUTES } from '../routes';

export const redirectToAuthApp = ({ redirect, session }) => {

  /**
   * QUICK Fix
   *
   * Short explenation:
   * Some time ago was used angular app. Then because of seo optimizations client part was remade on react with SSR
   * but we still used admin part from old app (thats why we have this redirectToAuthApp method).
   * Currently we also remade admin part on react so we don't need this redirect + cors.php (used to login user in angular app) for now.
   */
  if (1 === 1) {
    location.href = redirect || process.env.NEXT_PUBLIC_AUTH_PUBLIC_URL || '/';
    return;
  }

  // for SSR
  if (!document) return;

  // Fill corsData
  const corsData = { redirect: '/' };
  const localStorageParams = [
    'auth_token',
    'obsf_uuid',
    'email',
    'name',
    'customer_type',
    'customer_payment_status'
  ];
  localStorageParams.forEach(propName => {
    const value = LocalStorage.getItem(propName);
    if (typeof value !== 'undefined') corsData[propName] = value;
  });

  /**
   * Redirect
   *
   * For loggin and registration
   * - if Tutor (user_tutor =1) :
   *    - is_fully_validated: false -> redirect to https://espace.clevermate.scal3fl3x.com/validation
   *    - is_fully_validated: true -> redirect to  https://espace.clevermate.scal3fl3x.com/tutor/dashboard
   * - if Tutee (user_tutor =0) :
   *    - redirect to  https://espace.clevermate.scal3fl3x.com/tutee/profile/mon-compte
   */
  if (session) {
    // console.log("redirect service")
    const show_ai =
            session.statut_ai == 'A activer' || session.statut_ai == null;
          const see_ai = LocalStorage.getItem('see_ai_page') || 'on';
          const see_ai_ = see_ai === 'on' && show_ai && session.eligible_sai && session.wants_visio=='f';
          var tutee_route='/tutee/profile/mon-compte'
          tutee_route = session.customer_type === 'client'
              ? see_ai_ ? ROUTES.TUTEE_SAI : ROUTES.TUTEE_COURSE_MONITORING_PLANNING
              : ROUTES.TUTEE_START
    corsData.redirect =
      session.user_tutor === 1
        ? session.is_fully_validated
          ? '/tutor/dashboard'
          : '/validation'
        : tutee_route;
  }
  // Force redirect
  if (typeof redirect === 'string') corsData.redirect = redirect;

  // Create elements
  const form = document.createElement('FORM');
  form.method = 'post';
  form.action = `${process.env.NEXT_PUBLIC_AUTH_PUBLIC_URL}/php/cors.php`;

  const input = document.createElement('INPUT');
  input.type = 'hidden';
  input.name = 'cors';
  input.value = JSON.stringify(corsData);

  form.appendChild(input);
  document.body.appendChild(form);

  // Submit
  form.submit();
};
