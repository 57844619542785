export const orderTopics = (topics = {}) => {
  if (!topics) return;

  return Object.keys(topics)
    .sort(key => -['math', 'phys', 'fra', 'english'].indexOf(key))
    .map(
      key =>
        key === 'fra' ? 'Français' : key === 'phys' ? 'Physique' : topics[key]
    );
};

export const mention = input => {
  if (!input) return;

  return {
    F: 'Félicitations du jury',
    AB: 'Assez bien',
    B: 'Bien',
    TB: 'Très bien'
  }[input];
};
