import React from 'react';

const Loader = ({lg}) => (
  <i className={`fa fa-spinner fa-spin${lg ? 'fa-lg' : ''}`}/>
);

Loader.defaultProps = {
  lg: false
}

const LoaderContainer = ({className, ...otherProps}) => (
  <div className={className}><Loader {...otherProps}/></div>
);

LoaderContainer.defaultProps = {
  className: 'text-center'
}

export {
  Loader,
  LoaderContainer
};