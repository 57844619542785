import React, { Component, Fragment } from 'react';
import RouterLink from '../../../../../router-link';
import { ROUTES } from '../../../../../../routes';
import { ADMIN_ROUTES } from '../../../../../../admin/routes';

class UserDropdownMenuItem extends Component {
  constructor(props) {
    super(props);

    this.userDropdownRef = React.createRef();

    this.state = {
      dropdownOpened: false
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.globalClickHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.globalClickHandler);
  }

  globalClickHandler = ({ target }) => {
    if (this.dropdownOpened && !this.userDropdownRef.current.contains(target)) {
      this.setState({ dropdownOpened: false });
    }
  };

  closeDropdown = () => {
    this.setState({ dropdownOpened: false });
  };

  render() {
    const { currentUser, logout, redirectToAuthApp, isStartPage } = this.props;
    const { dropdownOpened } = this.state;
    const prospect = currentUser.customer_type === 'prospect';

    return (
      <li
        className={`dropdown ${dropdownOpened ? 'open' : ''}`}
        ref={this.userDropdownRef}
      >
        <a
          href="#"
          className="dropdown-toggle"
          onClick={ev => {
            ev.preventDefault();
            this.setState(({ dropdownOpened }) => ({
              dropdownOpened: !dropdownOpened
            }));
          }}
        >
          <i className="fa fa-gear" /> {currentUser.first_name}{' '}
          <span className="caret" />
        </a>

        {currentUser.user_tutor === 1 && (
          <ul className="dropdown-menu">
            {currentUser.is_onboarding_finished && (
              <Fragment>
                <li>
                  <RouterLink
                    route={ROUTES.TUTOR_DASHBOARD}
                    onBeforeClick={() => this.closeDropdown()}
                  >
                    <i className="fa fa-tachometer" /> Tableau de bord
                  </RouterLink>
                </li>

                <li>
                  <RouterLink
                    route={ADMIN_ROUTES.TUTOR_ACCOUNT_INFOCARD}
                    onBeforeClick={() => this.closeDropdown()}
                  >
                    <i className="fa fa-user" /> Mon profil
                  </RouterLink>
                </li>

                <li>
                  <RouterLink
                    route={ADMIN_ROUTES.TUTOR_REPORTS_COURSE}
                    onBeforeClick={() => this.closeDropdown()}
                  >
                    <i className="fa fa-list-alt" /> Mes réservations
                  </RouterLink>
                </li>
              </Fragment>
            )}

            {!currentUser.is_onboarding_finished && (
              <li>
                <RouterLink
                  route={ROUTES.TUTOR_ONBOARDING}
                  onBeforeClick={() => this.closeDropdown()}
                >
                  <i className="fa fa-list-alt" /> Valider mon compte
                </RouterLink>

                {/* <a
                  href="/validation"
                  onClick={ev => {
                    ev.preventDefault();
                    redirectToAuthApp('/validation');
                  }}
                >
                  <i className="fa fa-list-alt" /> Valider mon compte
                </a> */}
              </li>
            )}

            <li onClick={logout}>
              <a href="#" onClick={ev => ev.preventDefault()}>
                <i className="fa fa-power-off" /> Déconnexion
              </a>
            </li>
          </ul>
        )}

        {currentUser.user_tutor !== 1 && (
          <ul className="dropdown-menu">
            {!isStartPage && (
              <li>
                <RouterLink
                  route={ADMIN_ROUTES.TUTEE_ACCOUNT_PERSONAL_INFO}
                  onBeforeClick={() => this.closeDropdown()}
                >
                  <i className="fa fa-user" /> Mon compte
                </RouterLink>
              </li>
            )}

            {prospect && (
              <li>
                <RouterLink
                  route={ADMIN_ROUTES.TUTEE_START}
                  onBeforeClick={() => this.closeDropdown()}
                >
                  <i className="fa fa-bookmark" /> Souscrire
                </RouterLink>
              </li>
            )}

            <li onClick={() => logout()}>
              <a href="#" onClick={ev => ev.preventDefault()}>
                <i className="fa fa-power-off" /> Déconnexion
              </a>
            </li>
          </ul>
        )}
      </li>
    );
  }
}

export default UserDropdownMenuItem;
