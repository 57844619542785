import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { preloadReady } from 'react-loadable';
import { Frontload } from 'react-frontload';
import { ConnectedRouter } from 'connected-react-router';
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as ThemeProviderStyled } from "styled-components";
import createStore from './store';
import theme from './theme';

import './assets/styles/bootstrap-minified.css';
import App from './app';
import './assets/styles/index.scss';
import './routes/homepage/homepage.scss';

// Create a store and get back itself and its history object
const { store, history } = createStore();

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ThemeProviderStyled theme={theme}>
        <StylesProvider injectFirst>
          <ConnectedRouter history={history}>
            <Frontload noServerRender>
              <App/>
            </Frontload>
          </ConnectedRouter>
        </StylesProvider>
      </ThemeProviderStyled>
    </ThemeProvider>
  </Provider>
);

const root = document.querySelector('#root');

window.render = () => {
  if (process.env.NODE_ENV === 'production') {
    // If we're running in production, we use hydrate to get fast page loads by just
    // attaching event listeners after the initial render
    preloadReady().then(() => {
      hydrate(Application, root);
    });

    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function() {
        navigator.serviceWorker.register('/sw.js');
      });
    }
  }
  else {
    // If we're not running on the server, just render like normal
    render(Application, root);

    /**
     * Unregister serviceWorker, for case when we locally run localhost-server (it's register sw) and then run npm start
     */
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {
          registration.unregister();
        }
      }).catch(function(err) {
        console.log('Service Worker registration failed: ', err);
      });
    }
  }
};
