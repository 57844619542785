import * as apiService from '../services/api.service';

export const SET_USER_CONNECTIONS = 'userConnections/SET_CONNECTIONS';
export const SET_USER_CONNECTIONS_LOADER = 'userConnections/SET_LOADER';

const initialState = {
  connections: [],
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_CONNECTIONS:
      return {...state, connections: [...action.connections]};

    case SET_USER_CONNECTIONS_LOADER:
      return {...state, isLoading: action.isLoading};

    default:
      return state;
  }
};


export const getUserConnections = (user_uuid) => dispatch => {
  dispatch({ type: SET_USER_CONNECTIONS, connections: [] });
  dispatch({ type: SET_USER_CONNECTIONS_LOADER, isLoading: true });

  return apiService.getUserConnections(user_uuid)
    .then(({connections = []}) => {
      dispatch({ type: SET_USER_CONNECTIONS_LOADER, isLoading: false });
      return dispatch({ type: SET_USER_CONNECTIONS, connections });
    })
    .catch(error => {
      dispatch({ type: SET_USER_CONNECTIONS_LOADER, isLoading: false });
      // if (typeof alert === 'function') alert(error);
    });
}
