import cookie from 'js-cookie';
import {
  getLessonStatusRequest, setLessonStatusRequest, getUsersCompletionRequest, getSessionRequest,
  loginRequest, changeEmailRequest, changePhoneRequest, verifyEmailRequest, verifyPhoneRequest,
} from '../services/api.service';
import { LocalStorage } from '../services/localstorage.service';
import { redirectToAuthApp } from '../services/redirect.service';

export const AUTHENTICATE = 'auth/AUTHENTICATE';
export const SET_CURRENT_USER = 'auth/SET_CURRENT_USER';
export const SET_USER_DASHBOARD = 'auth/SET_USER_DASHBOARD';
export const SET_LESSON_STATUS = 'auth/SET_LESSON_STATUS';
export const SET_USER_LOADER = 'auth/SET_USER_LOADER';

const initialState = {
  isAuthenticated: null,
  isUserLoading: false,
  currentUser: {},
  userDashboard: {},
  lessonStatus: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return {...state, isAuthenticated: action.authenticated };

    case SET_CURRENT_USER:
      return {...state, currentUser: action.user};

    case SET_USER_LOADER:
      return {...state, isUserLoading: action.isUserLoading};

    case SET_USER_DASHBOARD:
      return {...state, userDashboard: action.userDashboard};

    case SET_LESSON_STATUS:
      return {...state, lessonStatus: action.lessonStatus};

    default:
      return state;
  }
};


export const updateLessonStatus = (uuid) => dispatch => {
  dispatch({ type: SET_LESSON_STATUS, lessonStatus: false });

  return getLessonStatusRequest(uuid)
    .then(({state: lessonStatus}) => dispatch({ type: SET_LESSON_STATUS, lessonStatus }));
}

export const saveLessonStatus = (uuid, status) => dispatch =>
  setLessonStatusRequest(uuid, status)
    .then(() => dispatch(updateLessonStatus(uuid)));

export const updateDashboard = (uuid) => dispatch => {
  dispatch({ type: SET_USER_DASHBOARD, userDashboard: {} });

  return getUsersCompletionRequest(uuid)
    .then(userDashboard => {
      dispatch({ type: SET_USER_DASHBOARD, userDashboard });

      return dispatch(updateLessonStatus(uuid));
    });
}
export const updateDashboardPercentage = (uuid) => dispatch => {
  dispatch({ type: SET_USER_DASHBOARD, userDashboard: {} });

  return getUsersCompletionRequest(uuid)
    .then(userDashboard => {
      return dispatch({ type: SET_USER_DASHBOARD, userDashboard });
    });
}

export const setCurrentUser = (user, options) => dispatch => {
  options = {
    updateDashboard: true,
    ...(options || {}),
  }

  if (typeof user === 'string') {
    try {
      const userObj = JSON.parse(user);
      user = userObj;
    } catch(error) {}
  }

  dispatch({ type: SET_CURRENT_USER, user });
  setAuthDataInStorage(user);
  dispatch({ type: AUTHENTICATE, authenticated: user && user.is_connected });

  if (options.updateDashboard && user && user.user_uuid) {
    dispatch(updateDashboard(user.user_uuid));
  }

  return Promise.resolve(user);
}

export const updateSession = (options) => dispatch => {
  options = {
    updateDashboard: true,
    showLoader: true,
    ...(options || {}),
  }

  if (options.showLoader) dispatch({ type: SET_USER_LOADER, isUserLoading: true });

  return getSessionRequest()
    .then(session => {
      cookie.set('mywebsite', JSON.stringify(session));
      dispatch(setCurrentUser(session, options));
      if (options.showLoader) dispatch({ type: SET_USER_LOADER, isUserLoading: false });
      return session;
    })
    .catch(error => {
      if (options.showLoader) dispatch({ type: SET_USER_LOADER, isUserLoading: false });
      dispatch(logoutUser());
      return error;
    });
}


export const establishCurrentUser = () => dispatch => dispatch(updateSession());

export const loginUser = (data = {}) => dispatch => {
  dispatch(logoutUser());

  return loginRequest(data).then(session => {
    LocalStorage.setItem('auth_token', session.auth_token || '');

    return dispatch(updateSession()).then(session => {
      // if (session.is_connected) redirectToAuthApp({ session });
      return session;
    });
  });
};

export const logoutUser = (withLogoutInAuthApp = false) => dispatch =>
  new Promise(resolve => {
    dispatch({ type: AUTHENTICATE, authenticated: false });
    dispatch({ type: SET_CURRENT_USER, user: {} });
    clearAuthDataFromStorage();

    if (withLogoutInAuthApp) redirectToAuthApp({});

    resolve({});
  });

export const changeEmail = (userUuid, email) => dispatch =>
  changeEmailRequest(userUuid, email)
    // .then(() => dispatch(updateSession()));

export const changePhone = (userUuid, phone) => dispatch =>
  changePhoneRequest(userUuid, phone)
    // .then(() => dispatch(updateSession()));

export const verifyEmail = (userUuid, code) => dispatch =>
  verifyEmailRequest(userUuid, code)
    .then(() => dispatch(updateSession({showLoader: false, updateDashboard: false})));
  
export const verifyPhone = (userUuid, code) => dispatch =>
  verifyPhoneRequest(userUuid, code)
    .then(() => dispatch(updateSession({showLoader: false, updateDashboard: false})));


/**
 * HELPERS
 */
function setAuthDataInStorage(session) {
  const storageData = {};

  if (session.customer_type)
    storageData['customer_type'] = session.customer_type;
  if (session.user) {
    if (session.user.obsf_uuid)
      storageData['obsf_uuid'] = session.user.obsf_uuid;
    if (session.user.first_name) storageData['name'] = session.user.first_name;
  }
  if (session.email) storageData['email'] = session.email;
  if (session.api_auth_token)
    storageData['auth_token'] = session.api_auth_token;
  if (session.customer_payment_status)
    storageData['customer_payment_status'] = session.customer_payment_status;

  Object.keys(storageData).forEach(propName =>
    LocalStorage.setItem(propName, storageData[propName])
  );
}

function clearAuthDataFromStorage() {
  ['auth_token', 'obsf_uuid', 'email', 'name'].forEach(propName =>
    LocalStorage.removeItem(propName)
  );

  cookie.remove('mywebsite');
}
