import React from 'react'
import { Button, withStyles } from '@material-ui/core'

const StyledButton = withStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 0,
    color: 'white',
    boxShadow: 'none',
    padding: '12px 22px',
    fontWeight: 700,
  },
}))(Button)

export default function CustomButton({
  variant = 'contained',
  size = 'large',
  children,
  ...props
}) {
  return (
    <StyledButton variant={variant} size={size} {...props}>
      {children}
    </StyledButton>
  )
}
