import * as apiService from '../services/api.service';

export const SET_SCHOOLS = 'school/SET_SCHOOLS';
export const SET_SCHOOL = 'school/SET_SCHOOL';
export const SET_SCHOOL_TUTORS = 'school/SET_SCHOOL_TUTORS';

const initialState = {
  schools: [],
  school: {},
  tutors: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SCHOOLS:
      return {
        ...state,
        schools: [...action.schools]
      };
    
    case SET_SCHOOL:
      return {
        ...state,
        school: action.school
      };
    
    case SET_SCHOOL_TUTORS:
      return {
        ...state,
        tutors: action.users
      };
    
    default:
      return state;
  }
};

export const getSchools = () => dispatch =>
  new Promise(resolve => {
    apiService
      .getSchools()
      .then(response => response && response.schools)
      .then((schools = []) => {
        dispatch({ type: SET_SCHOOLS, schools });
        
        resolve(schools);
      })
      .catch(error => {
        console.log(error);
        // if (typeof alert === 'function') alert(error);
      });
  });

export const getSchool = slug => dispatch =>
  new Promise(resolve => {
    apiService
      .getSchool(slug)
      .then(response => response && response.school)
      .then((school = {}) => {
        dispatch(setSchool(school));
        
        resolve(school);
      })
      .catch(error => {
        // if (typeof alert === 'function') alert(error);
      });
  });

export const getCurrentUsersBySchool = slug => dispatch =>
  new Promise(resolve => {
    apiService
      .getCurrentUsersBySchool(slug)
      .then(response => response && response.users)
      .then((users = {}) => {
        dispatch(setTutors(users));
        
        resolve(users);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        // if (typeof alert === 'function') alert(error);
      });
  });

export const setSchool = school => ({ type: SET_SCHOOL, school });
export const setTutors = users => ({ type: SET_SCHOOL_TUTORS, users });
