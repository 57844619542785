import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Helmet from 'react-helmet';
import * as metadata from '../../metadata';

class Page extends Component {
  render() {
    const {
      children,
      id,
      className,
      history,
      profileImage,
      style,
      metaDescription,
      ...rest
    } = this.props;
    const meta = [
      {
        property: 'og:url',
        content: metadata.NEXT_PUBLIC_SITE_URL + history.location.pathname
      },
      {
        property: 'og:title',
        content: rest.title
      },
      {
        itemprop: 'name',
        content: rest.title
      }
    ];

    if (metaDescription) {
      meta.push(
        {
          name: 'description',
          content: metaDescription
        }
      );
    }

    if (profileImage)
      meta.push(
        {
          property: 'og:image',
          content: profileImage
        },
        {
          itemprop: 'image',
          content: profileImage
        }
      );

    const link = [
      {
        rel: 'canonical',
        href: process.env.NEXT_PUBLIC_SITE_URL + history.location.pathname
      }
    ];

    return (
      <div
        id={id}
        className={`main${className ? ' ' + className : ''}`}
        style={style || {}}
      >
        <Helmet title={rest.title} meta={meta} link={link} />
        {children}
      </div>
    );
  }
}

export default withRouter(Page);
