import * as apiService from '../services/api.service';

export const SET_USER_META = 'userMeta/SET_META';
export const SET_USER_META_LOADER = 'userMeta/SET_LOADER';
export const SET_USER_META_SAVING_LOADER = 'userMeta/SET_SAVING_LOADER';

const initialState = {
  meta: {},
  isLoading: false,
  isSaving: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_META:
      return {...state, meta: {...action.meta}};

    case SET_USER_META_LOADER:
      return {...state, isLoading: action.isLoading};

    case SET_USER_META_SAVING_LOADER:
      return {...state, isSaving: action.isLoading};

    default:
      return state;
  }
};


export const getUserMeta = (user_uuid) => dispatch => {
  dispatch({ type: SET_USER_META, meta: {} });
  dispatch({ type: SET_USER_META_LOADER, isLoading: true });

  return apiService.getUserMeta(user_uuid)
    .then(({meta = {}}) => {
      dispatch({ type: SET_USER_META_LOADER, isLoading: false });
      return dispatch({ type: SET_USER_META, meta });
    })
    .catch(error => {
      dispatch({ type: SET_USER_META_LOADER, isLoading: false });
      // if (typeof alert === 'function') alert(error);
    });
}


export const saveUserMeta = (user_uuid, meta) => dispatch => {
  dispatch({ type: SET_USER_META_SAVING_LOADER, isLoading: true });

  return apiService.setUserMeta(user_uuid, meta)
    .then(response => {
      dispatch({ type: SET_USER_META_SAVING_LOADER, isLoading: false });
      dispatch({ type: SET_USER_META, meta: response.meta });
      return response;
      // return dispatch(getUserMeta(user_uuid));
    })
    .catch(error => {
      dispatch({ type: SET_USER_META_SAVING_LOADER, isLoading: false });
      // if (typeof alert === 'function') alert(error);
    });
}
