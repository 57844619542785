import { fbConnectRequest } from './api.service';

/**
 * Code from old app!
 */

let loginHandlerFunction, registrationHandlerFunction, errorHandlerFunction;

export const initFB = () => {
  if (typeof FB !== 'undefined') {
    init();
  } else if (typeof window !== 'undefined') {
    window.fbAsyncInit = init;
  }

  function init() {
    FB.init({
      appId: process.env.NEXT_PUBLIC_FB_APP_ID,
      version: process.env.NEXT_PUBLIC_FB_VERSION
    });
  }
};

export const setLoginHandler = fn => {
  if (typeof fn === 'function') loginHandlerFunction = fn;
};
export const setRegistrationHandler = fn => {
  if (typeof fn === 'function') registrationHandlerFunction = fn;
};
export const setErrorHandler = fn => {
  if (typeof fn === 'function') errorHandlerFunction = fn;
};

/**
 * Send fb-login data to API
 *
 * API Facebook Connect / Clevermate
 * POST /api_v2/users/connect/facebook
 * JSON data: {'data': {'token': 'rzzetetertertert', 'facebook_id': '829384938243', 'email': 'julian.dmk@gmail.com'}}
 * Response:
 * 1. User already exists (= login):
 * {'status': 'success', 'is_prod': false, 'type': 'login', 'user_uuid': '...', 'auth_token': '...', 'user_tutor': 0}
 * 2. User doesn't exist (= registration):
 * {'status': 'success', 'is_prod': false, 'login': 'registration', 'msg': 'Please register now...'}
 *
 * @param fb_login_data   FB login response data.
 * For example:
 * {
 *    'authResponse': {'accessToken':'...', 'userID':'829768400445580', 'expiresIn':6862, 'signedRequest':'...'},
 *    'status':'connected'
 * }
 *
 * @param fb_user_fields_data   FB user data
 * For example: {'id':'829768400445580','email':'vitaly.shaposhnik@gmail.com','first_name':'Vitaly'}
 */
const sendConnectDataToAPI = (fb_login_data, fb_user_fields_data) => {
  fbConnectRequest({
    token: fb_login_data.authResponse.accessToken || null,
    facebook_id: fb_user_fields_data.id || null,
    email: fb_user_fields_data.email || null
  })
    .then(response => {
      const handlerParams = { response, fb_user_fields_data };

      if (!response || response.status !== 'success')
        // error handler
        return;

      if (response.type && response.type === 'login' && loginHandlerFunction)
        loginHandlerFunction(handlerParams);
      else if (
        response.login &&
        response.login === 'registration' &&
        registrationHandlerFunction
      )
        registrationHandlerFunction(handlerParams);
    })
    .catch(response => {
      if (errorHandlerFunction) errorHandlerFunction(response);
    });
};

/**
 * Get user data from FB
 * @param fields  {string}  Not required field. A list of fields separated by a coma.
 *  Example: 'name,picture,email,age_range,location,birthday,photos,work'
 *
 * @returns {promise}
 */
const getUserDataFromFB = fields =>
  new Promise((resolve, reject) => {
    FB.api(
      '/me' + (fields && fields.length ? '?fields=' + fields : ''),
      response => {
        if (response && !response.error) resolve(response);
        else reject(response);
      }
    );
  });

const loginHandler = login_response => {
  if (login_response.status === 'connected')
    getUserDataFromFB('id,email,first_name').then(fb_user_data =>
      sendConnectDataToAPI(login_response, fb_user_data)
    );
};

export const startFbConnect = () =>
  FB.login(loginHandler, { scope: 'public_profile, email' });
